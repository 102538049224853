import { Pagination } from '.';

export interface ReportInvoice {
  id: string;
  fileId: string;
  createdAt: string;
  status: string;
  message: string;
}

export interface ServiceProviderEmployee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ServiceProvider {
  id: string;
  name: string;
}

export interface MonthlyReport {
  id: string;
  fileId: string;
  serviceProvider: ServiceProvider;
  invoice: ReportInvoice;
  createdAt: string;
  status: string;
}

export enum Period {
  OWN_PERIOD = 'Own period',
  CURRENT_MONTH = 'Current month',
  PREVIOUS_MONTH = 'Previous month',
  CURRENT_YEAR = 'Current year',
  PREVIOUS_YEAR = 'Previous year',
}

export interface GenerateReportPayload {
  fromDate: string;
  toDate: string;
  status?: string;
  companies?: string[];
  type?: string;
}

export interface SlaReportListParams extends Pagination {
  companyId?: string;
}

export interface MonthlyReportFilters {
  companyId?: string;
  invoiceStatus?: string;
  status?: string;
  date?: string;
}

export interface SlaReportResponse {
  id: number;
  companyId: string;
  doneRequest: number;
  averageDays: number;
  createdAt: string;
}

export interface SlaReport extends SlaReportResponse {
  companyName?: string;
}

export interface InvoicePayload {
  status: string;
  message: string | null;
}
