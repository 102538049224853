export type ActionMenuItem = {
  label: string;
  action: () => void;
  condition?: () => boolean;
  disabled: boolean;
};
export enum PublishActions {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
}

export type BasePublishType = {
  isPublished?: boolean;
  [key: string]: any;
};

export type BaseMandateType = {
  isMandated?: boolean;
  [key: string]: any;
};

export enum MandateActions {
  MANDATE = 'Mandate',
  REMOVE_MANDATE = 'Remove mandate',
}
