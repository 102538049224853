import { capitalize } from 'lodash';

import { CompatibilityStatus, StatusDetails, StatusType } from '@/common/types';

import {
  BaseStatusDetails,
  CompatibilityStatusDetails,
  ContractStatusDetails,
  FastTrackRequestAndRecordStatusDetails,
  InvoiceStatusDetails,
  PaymentStatusDetails,
  ReportStatusDetails,
  RequestStatusDetails,
  StatusesColors,
} from '../constants';

export const transformToStatusKey = (status: string): string => status?.toLowerCase()?.replace(/ /g, '_');
export const transformToOriginalValue = (status: string): string => capitalize(status)?.replaceAll('_', ' ');

export const getCompatibilityStatusesObjects = (hasUnableToVerifyAndPending = false) => {
  const compatibilityStatuses = [
    {
      value: transformToOriginalValue(CompatibilityStatus.COMPATIBLE),
      nameEnglish: transformToOriginalValue(CompatibilityStatus.COMPATIBLE),
      nameArabic: 'ملائم',
    },
    {
      value: transformToOriginalValue(CompatibilityStatus.INCOMPATIBLE),
      nameEnglish: transformToOriginalValue(CompatibilityStatus.INCOMPATIBLE),
      nameArabic: 'غير ملائم',
    },
  ];

  if (hasUnableToVerifyAndPending) {
    compatibilityStatuses.push(
      {
        value: transformToOriginalValue(CompatibilityStatus.UNABLE_TO_VERIFY),
        nameEnglish: transformToOriginalValue(CompatibilityStatus.UNABLE_TO_VERIFY),
        nameArabic: 'غير قادر على التحقق',
      },
      {
        value: transformToOriginalValue(CompatibilityStatus.PENDING),
        nameEnglish: transformToOriginalValue(CompatibilityStatus.PENDING),
        nameArabic: 'قيد الانتظار',
      },
    );
  }

  return compatibilityStatuses;
};

// FIXME: specify type for list
export const getStatusDetails =
  (list: StatusDetails<any>) =>
  (status: StatusType, defaultColor = StatusesColors.dark) =>
    list[status] ?? { translationKey: null, color: defaultColor };

export const getPaymentStatusDetails = getStatusDetails(PaymentStatusDetails);
export const getContractStatusDetails = getStatusDetails(ContractStatusDetails);
export const getRequestStatusDetails = getStatusDetails(RequestStatusDetails);
export const getFastTrackStatusDetails = getStatusDetails(FastTrackRequestAndRecordStatusDetails);
export const getCompatibilityStatusDetails = getStatusDetails(CompatibilityStatusDetails);
export const getReportStatusDetails = getStatusDetails(ReportStatusDetails);
export const getInvoiceStatusDetails = getStatusDetails(InvoiceStatusDetails);
export const getBaseStatusDetails = getStatusDetails(BaseStatusDetails);
