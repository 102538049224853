export enum Role {
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  Admin = 'ROLE_ADMIN',
  CustomerServiceEditor = 'ROLE_CUSTOMER_SERVICE_EDITOR',
  CustomerServiceViewer = 'ROLE_CUSTOMER_SERVICE_VIEWER',
  BackOfficeVerifier = 'ROLE_BACK_OFFICE',
  ServiceProviderManager = 'ROLE_SERVICE_PROVIDER_MANAGER',
  ServiceProvider = 'ROLE_SERVICE_PROVIDER_EMPLOYEE',
  User = 'ROLE_CANDIDATE',
  HrsdManager = 'ROLE_HRSD_MANAGER',
  HrsdViewer = 'ROLE_HRSD_VIEWER',
  HrsdFastTrackManager = 'ROLE_HRSD_FAST_TRACK_MANAGER',
}

export enum MemberStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
}

export interface RoleType {
  name: string;
  nameArabic?: string;
  value: string;
}

export interface RolesMap {
  [key: string]: RoleType;
}

export enum ActionMode {
  SP = 'ServiceProvider',
  ADMIN = 'Admin',
}

export interface InvitePayload {
  email: string;
  role?: string;
  resend?: boolean;
  company?: string;
  verificationTypes?: string[];
}

export interface RoleChangePayload {
  userId: string;
  newRole: string;
}

export default Role;
