import { RequestStatuses } from '@/common/types';

export const requestStatusesAllowedToWithdraw = [
  RequestStatuses.PAID.valueOf(),
  RequestStatuses.UNPAID.valueOf(),
  RequestStatuses.PENDING.valueOf(),
  RequestStatuses.IN_PROGRESS.valueOf(),
  RequestStatuses.ON_HOLD.valueOf(),
  RequestStatuses.RETURNED.valueOf(),
  RequestStatuses.UPDATED.valueOf(),
  RequestStatuses.UNABLE_TO_VERIFY.valueOf(),
  RequestStatuses.ADDITIONAL_PAYMENT_REQUESTED.valueOf(),
];
