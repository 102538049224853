var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M24 2L6 10V22C6 33.1 13.68 43.48 24 46C34.32 43.48 42 33.1 42 22V10L24 2ZM38 22C38 31.04 32.04 39.38 24 41.86C15.96 39.38 10 31.04 10 22V12.6L24 6.38L38 12.6V22ZM14.82 23.18L12 26L20 34L36 18L33.18 15.16L20 28.34L14.82 23.18Z",
          fill: "#E69A35",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }