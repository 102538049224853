import { CountryData, Role, TokenData } from '@/common/types';

export enum LocalStorageKey {
  Token = 'token',
  RefreshToken = 'refresh-token',
  UserPayload = 'user-payload',
  UserId = 'user-id',
  CountryList = 'country-list',
  UserRoles = 'user-roles',
  SpManagerProfileFilled = 'sp-manager-profile-filled',
  AutoLogoutTimestamp = 'auto-logout-timestamp',
}

function getItem(key: LocalStorageKey) {
  return localStorage.getItem(key);
}

function setItem(key: LocalStorageKey, token: string | null) {
  if (token) {
    localStorage.setItem(key, token);
  } else {
    localStorage.removeItem(key);
  }
}

function removeItem(key: LocalStorageKey) {
  localStorage.removeItem(key);
}

interface UserRolesStorage {
  roles: Role[];
  mainRole: Role;
}

export const getToken = () => getItem(LocalStorageKey.Token);
export const getRefreshToken = () => getItem(LocalStorageKey.RefreshToken);
export const getUserPayload = (): TokenData => JSON.parse(getItem(LocalStorageKey.UserPayload) || '{}') as TokenData;
export const getUserId = () => getItem(LocalStorageKey.UserId);
export const getUserRoles = (): UserRolesStorage => JSON.parse(getItem(LocalStorageKey.UserRoles) || '{}');
export const getCountryList = () => JSON.parse(getItem(LocalStorageKey.CountryList) || '[]') as CountryData[];
export const getSpManagerProfileStatus = () => getItem(LocalStorageKey.SpManagerProfileFilled);
export const getAutoLogoutTimestamp = () => Number(getItem(LocalStorageKey.AutoLogoutTimestamp));

export const setToken = (token: string | null) => setItem(LocalStorageKey.Token, token);
export const setRefreshToken = (token: string | null) => setItem(LocalStorageKey.RefreshToken, token);
export const setUserPayload = (data: TokenData | null) => setItem(LocalStorageKey.UserPayload, JSON.stringify(data));
export const setUserId = (id: string | null) => setItem(LocalStorageKey.UserId, id);
export const setUserRoles = (data: UserRolesStorage) => setItem(LocalStorageKey.UserRoles, JSON.stringify(data));
export const setCountryList = (data: CountryData[]) => setItem(LocalStorageKey.CountryList, JSON.stringify(data));
export const setSpManagerProfileStatus = (profileFilled: string | null) =>
  setItem(LocalStorageKey.SpManagerProfileFilled, profileFilled);
export const setAutoLogoutTimestamp = (timestamp: string | null) =>
  setItem(LocalStorageKey.AutoLogoutTimestamp, timestamp);

export const removeUserPayload = () => {
  removeItem(LocalStorageKey.UserPayload);
  removeItem(LocalStorageKey.UserId);
};
export const removeUserRoles = () => removeItem(LocalStorageKey.UserRoles);
