var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "61",
        viewBox: "0 0 60 61",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "30", cy: "30.5293", r: "30", fill: "#E69A35" },
      }),
      _c("path", {
        attrs: {
          d: "M33.8313 30.1936L22.0269 41.998C20.8743 43.1506 19.0134 43.1506 17.8607 41.998C17.5869 41.7245 17.3697 41.3998 17.2215 41.0424C17.0733 40.6849 16.9971 40.3018 16.9971 39.9148C16.9971 39.5279 17.0733 39.1448 17.2215 38.7873C17.3697 38.4299 17.5869 38.1052 17.8607 37.8317L29.6651 26.0273",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.4971 34.3596L43.552 28.3047",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.039 29.7783L40.3031 28.0424C39.4698 27.2091 39.0116 26.0981 39.0116 24.9177V23.7234L35.2342 19.9182C34.5171 19.1969 33.6645 18.6244 32.7255 18.2335C31.7865 17.8427 30.7796 17.6412 29.7625 17.6406H25.499L26.7767 18.7794C27.6842 19.584 28.4108 20.5719 28.9087 21.6778C29.4066 22.7837 29.6645 23.9826 29.6653 25.1954V27.3619L32.4428 30.1394H35.873L39.0116 32.7919",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }