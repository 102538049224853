export enum RegistrationSteps {
  REGISTRATION_DETAILS = 1,
  VERIFICATION = 2,
}

export enum AdminRegistrationSteps {
  REGISTRATION_DETAILS = 1,
  PERSONAL_DATA = 2,
}

export enum SPRegistrationSteps {
  REGISTRATION_DETAILS = 1,
  PERSONAL_DATA = 2,
  COMPANY_DATA = 3,
}

export enum VerificationFlowSteps {
  PERSONAL_DETAILS = 1,
  EDUCATION = 2,
  EMPLOYMENT = 3,
  SUMMARY = 4,
}

export enum VerifyRequest {
  PERSONAL_DETAILS = 1,
  EDUCATION = 2,
  SUMMARY = 3,
}

export enum FastTrackImmediateRequestSteps {
  PERSONAL_DETAILS = 1,
  ENTITY = 2,
  DOCUMENTS = 3,
  SUMMARY = 4,
}

export enum FastTrackMajorEntityRequestSteps {
  ENTITY = 1,
  DOCUMENTS = 2,
  SUMMARY = 3,
}
