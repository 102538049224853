<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M15.7773 41.3041C15.7773 42.0408 16.07 42.7472 16.5909 43.2681C17.1117 43.789 17.8182 44.0816 18.5548 44.0816H40.7748C41.5115 44.0816 42.218 43.789 42.7388 43.2681C43.2597 42.7472 43.5523 42.0408 43.5523 41.3041V24.6391L33.8311 31.5829V24.6391L24.1098 31.5829V19.0841C24.1098 18.3475 23.8172 17.641 23.2963 17.1202C22.7755 16.5993 22.069 16.3066 21.3323 16.3066H18.5548C17.8182 16.3066 17.1117 16.5993 16.5909 17.1202C16.07 17.641 15.7773 18.3475 15.7773 19.0841V41.3041Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.6084 38.5273H37.9971"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.665 38.5273H31.0538"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.7217 38.5273H24.1104"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IndustryIcon',
});
</script>
