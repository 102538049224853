var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "61",
        viewBox: "0 0 60 61",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "30", cy: "30.5293", r: "30", fill: "#E69A35" },
      }),
      _c("path", {
        attrs: {
          d: "M40.7747 30.1934V44.0809H18.5547V30.1934",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.5523 23.25H15.7773V30.1938H43.5523V23.25Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.665 44.0813V23.25",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.6646 23.2504H23.4152C22.4944 23.2504 21.6114 22.8846 20.9602 22.2335C20.3091 21.5824 19.9434 20.6993 19.9434 19.7785C19.9434 18.8577 20.3091 17.9746 20.9602 17.3235C21.6114 16.6724 22.4944 16.3066 23.4152 16.3066C28.2759 16.3066 29.6646 23.2504 29.6646 23.2504Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.665 23.2504H35.9144C36.8352 23.2504 37.7183 22.8846 38.3694 22.2335C39.0205 21.5824 39.3863 20.6993 39.3863 19.7785C39.3863 18.8577 39.0205 17.9746 38.3694 17.3235C37.7183 16.6724 36.8352 16.3066 35.9144 16.3066C31.0538 16.3066 29.665 23.2504 29.665 23.2504Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }