<template>
  <svg
    width="105"
    height="124"
    viewBox="0 0 105 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9705_79458)">
      <path
        d="M31.6953 87.4793C29.9553 87.4793 28.5353 86.0493 28.5353 84.2893C28.5353 82.5293 29.9453 81.0993 31.6953 81.0993H52.1953C53.9353 81.0993 55.3553 82.5293 55.3553 84.2893C55.3553 86.0493 53.9453 87.4793 52.1953 87.4793H31.6953ZM31.6953 42.7993C29.9553 42.7993 28.5453 41.3893 28.5453 39.6493C28.5453 37.9093 29.9553 36.4993 31.6953 36.4993H73.3053C75.0453 36.4993 76.4553 37.9093 76.4553 39.6493C76.4553 41.3893 75.0453 42.7993 73.3053 42.7993H31.6953ZM66.7253 109.189C65.3653 108.109 65.1353 106.129 66.2253 104.769C67.3053 103.409 69.2853 103.179 70.6453 104.269L80.2153 111.859L98.4253 89.5893C99.5253 88.2393 101.515 88.0493 102.855 89.1493C104.205 90.2493 104.395 92.2393 103.295 93.5793L83.1253 118.249C82.0353 119.579 80.0853 119.789 78.7353 118.719L66.7253 109.189ZM57.0053 117.109C58.7453 117.109 60.1553 118.519 60.1553 120.259C60.1553 121.999 58.7453 123.409 57.0053 123.409H7.48527C5.46527 123.409 3.63527 122.589 2.30527 121.259C0.975273 119.929 0.155273 118.099 0.155273 116.079V7.8593C0.155273 5.8393 0.975273 4.0093 2.30527 2.6793C3.63527 1.3493 5.46527 0.529297 7.48527 0.529297H97.5053C99.5253 0.529297 101.355 1.3493 102.685 2.6793C104.015 4.0093 104.835 5.8393 104.835 7.8593V73.1293C104.835 74.8693 103.425 76.2793 101.685 76.2793C99.9453 76.2793 98.5353 74.8693 98.5353 73.1293V7.8593C98.5353 7.5793 98.4153 7.3193 98.2353 7.1293C98.0453 6.9393 97.7853 6.8293 97.5053 6.8293H7.48527C7.20527 6.8293 6.94527 6.9493 6.75527 7.1293C6.57527 7.3293 6.45527 7.5793 6.45527 7.8593V116.069C6.45527 116.349 6.57527 116.609 6.75527 116.799C6.94527 116.989 7.20527 117.099 7.48527 117.099H57.0053V117.109ZM31.6953 65.1193C29.9553 65.1193 28.5453 63.7093 28.5453 61.9693C28.5453 60.2293 29.9553 58.8193 31.6953 58.8193H73.3053C75.0453 58.8193 76.4553 60.2293 76.4553 61.9693C76.4553 63.7093 75.0453 65.1193 73.3053 65.1193H31.6953Z"
        fill="#E69A35"
      />
    </g>
    <defs>
      <clipPath id="clip0_9705_79458">
        <rect
          width="104.69"
          height="122.88"
          fill="white"
          transform="translate(0.155273 0.529297)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentIcon',
});
</script>
