/* eslint-disable */
import vuetify from '@/plugins/vuetify';
import { DirectiveBinding, DirectiveOptions } from 'vue/types/options';

export const vVisible = (el: HTMLElement, binding: DirectiveBinding) => {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
};

type ExpandGridValue = 'start' | 'end';

function isExpandGridValue(value: any): value is ExpandGridValue {
  return value === 'start' || value === 'end';
}

export const vExpandGrid: DirectiveOptions & { listener?: () => void } = {
  inserted(el, { value = 'end' }) {
    if (!isExpandGridValue(value)) {
      throw new Error('Incorrect value set for v-expad directive.');
    }

    const expandGridListener = () => {
      const isRtl = vuetify.framework.rtl;
      const isExpandDirLeft = (value === 'end' && isRtl) || (value === 'start' && !isRtl);
      el.style.minWidth = 'unset';
      el.style.marginInlineStart = '0';
      let gridNode = el;
      let elBounding = gridNode.getBoundingClientRect();

      while (getComputedStyle(gridNode).getPropertyValue('position') === 'fixed') {
        gridNode = el.parentElement!;
        elBounding = gridNode.getBoundingClientRect();
      }

      const browserWidth = document.body.offsetWidth;
      const elExpadedWidth = isExpandDirLeft ? elBounding.right : browserWidth - elBounding.left;
      el.style.minWidth = `${elExpadedWidth}px`;
      el.style.maxWidth = `${elExpadedWidth}px`;

      if (value === 'start') {
        el.style.marginInlineStart = `-${isRtl ? browserWidth - elBounding.right : elBounding.left}px`;
      }
    };
    expandGridListener();
    window.addEventListener('resize', expandGridListener);
    (el as any).expandGridListener = expandGridListener;
  },
  unbind(el) {
    window.removeEventListener('resize', (el as any).expandGridListener);
  },
};

export default vVisible;
