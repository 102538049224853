<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M29.665 16.3066V19.0841"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.25 20.7793L22.222 22.7513"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.7754 30.1934H43.5529"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.1786 30.8878C35.3164 29.7907 35.1236 28.6773 34.6249 27.6904C34.1262 26.7034 33.3443 25.8878 32.3792 25.3478C31.4142 24.8079 30.31 24.5683 29.208 24.6596C28.106 24.751 27.0563 25.1691 26.1934 25.8606"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.9295 44.0829C33.1403 44.109 34.3123 43.6548 35.1892 42.8195C36.0662 41.9841 36.5768 40.8357 36.6096 39.625C36.5952 39.0231 36.4623 38.4299 36.2186 37.8794C35.9748 37.3289 35.625 36.8318 35.1891 36.4165C34.7532 36.0013 34.2397 35.6759 33.678 35.4592C33.1164 35.2424 32.5175 35.1384 31.9156 35.1532H30.0547C29.5661 33.6931 28.6262 32.426 27.3706 31.5349C26.1151 30.6438 24.6088 30.1747 23.0693 30.1954C21.182 30.1506 19.3541 30.8567 17.9871 32.1587C16.62 33.4606 15.8256 35.2519 15.7783 37.1391C15.7783 40.9721 19.0419 44.0829 23.0693 44.0829H31.9295Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.1084 22.7513L39.0804 20.7793"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TourismIcon',
});
</script>
