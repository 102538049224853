import { SlaStatus } from '@/common/types/sla';
import { VerificationRequestSummary } from '@/common/types/verification-form';

export enum NotificationTypes {
  ON_HOLD = 'sla_status_change',
  WITHDRAW_CONSENT = 'withdrew_consent',
  REQUEST_REASSIGN = 'verification_request_assign',
  VERIFICATION_REQUEST_STATUS_CHANGE = 'verification_request',
  FAST_TRACK_IMMEDIATE_SUBMITTED = 'fast_track_immediate_request_submitted',
  FAST_TRACK_MAJOR_SUBMITTED = 'fast_track_major_entity_request_submitted',
  FAST_TRACK_REQUEST_NEED_UPDATE = 'fast_tack_request_pending_updates',
  FAST_TRACK_REQUEST_UPDATED = 'fast_track_request_updated',
}

export interface Notification {
  id: string;
  active: boolean | null;
  candidateId: string | null;
  companyName: string | null;
  content: string | null;
  createdAt: string;
  employeeId: string | null;
  new: boolean;
  randomId: string | null;
  requestId: string | null;
  slaHoldEntityId: string | null;
  slaStatusChange: SlaStatus | null;
  status: string | null;
  type: NotificationTypes;
  userId: string;
  updatedByFirstName: string | null;
  updatedByLastName: string | null;
}

export interface DetailNotification {
  item: Notification;
  verificationRequest: VerificationRequestSummary | null;
}
