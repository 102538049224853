export function injectZendesk(zendeskKey: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'ze-snippet';
  script.defer = true;
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
  const firstScript = document.getElementsByTagName('script')[0];

  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(script, firstScript);
  } else {
    console.error('Zendesk initialization failed');
  }
}

export default injectZendesk;
