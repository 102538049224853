var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "61",
        viewBox: "0 0 60 61",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "30", cy: "30.5293", r: "30", fill: "#E69A35" },
      }),
      _c("path", {
        attrs: {
          d: "M22.7217 41.3047H36.6092",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.8877 41.3035C34.5258 37.8316 27.9987 32.4155 31.0539 27.416",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.1928 26.5843C27.7204 27.6953 28.6926 29.6396 29.3869 31.7227C26.6094 32.2782 24.5263 32.2782 22.7209 31.3061C21.0544 30.4728 19.5268 28.6675 18.5547 25.4733C22.4432 24.779 24.6652 25.4733 26.1928 26.5843V26.5843Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.5816 21.8615C31.5228 23.5163 30.9902 25.453 31.054 27.4165C33.6926 27.2776 35.6368 26.5832 37.0256 25.4722C38.4143 24.0835 39.2476 22.2781 39.3865 19.084C35.6368 19.2229 33.8315 20.4727 32.5816 21.8615Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }