<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M15.7773 17.6953H24.1098C25.5831 17.6953 26.9961 18.2806 28.0378 19.3223C29.0796 20.3641 29.6648 21.777 29.6648 23.2503V42.6928C29.6648 41.5879 29.2259 40.5282 28.4446 39.7468C27.6633 38.9655 26.6036 38.5266 25.4986 38.5266H15.7773V17.6953Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.5525 17.6953H35.22C33.7468 17.6953 32.3338 18.2806 31.2921 19.3223C30.2503 20.3641 29.665 21.777 29.665 23.2503V42.6928C29.665 41.5879 30.104 40.5282 30.8853 39.7468C31.6666 38.9655 32.7263 38.5266 33.8313 38.5266H43.5525V17.6953Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CultureIcon',
});
</script>
