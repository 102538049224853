import { FastTrackRecordsTableKeys, FastTrackRequestType } from '../types';

export const fastTrackSubmitted = [
  { name: 'Submitted', nameArabic: 'تم إنشاؤها', value: 'submitted', color: '#2196F3' },
];
export const fastTrackPendingUpdates = [
  { name: 'Pending Updates', nameArabic: 'بانتظار التحديث', value: 'pending_updates', color: '#2196F3' },
];
export const fastTrackUpdated = [{ name: 'Updated', nameArabic: 'محدث', value: 'updated', color: '#2196F3' }];
export const fastTrackApproved = [{ name: 'Approved', nameArabic: 'مقبول', value: 'approved', color: '#4CAF50' }];
export const fastTrackRejected = [{ name: 'Rejected', nameArabic: 'مرفوض', value: 'rejected', color: '#F44336' }];
export const fastTrackActive = [{ name: 'Active', nameArabic: 'مفعل', value: 'active', color: '#4CAF50' }];
export const fastTrackCanceled = [{ name: 'Canceled', nameArabic: 'ملغي', value: 'canceled', color: '#F44336' }];

export const fastTrackRequestStatuses = [
  ...fastTrackSubmitted,
  ...fastTrackPendingUpdates,
  ...fastTrackUpdated,
  ...fastTrackApproved,
  ...fastTrackRejected,
];

export const fastTrackRecordsStatuses = [...fastTrackActive, ...fastTrackCanceled];

export type FastTrackRequestAndRecordTableKeys = FastTrackRequestType | FastTrackRecordsTableKeys;
