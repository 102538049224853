<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M39.386 19.084H19.9435C18.4095 19.084 17.166 20.3275 17.166 21.8615V32.9715C17.166 34.5055 18.4095 35.749 19.9435 35.749H39.386C40.92 35.749 42.1635 34.5055 42.1635 32.9715V21.8615C42.1635 20.3275 40.92 19.084 39.386 19.084Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.7773 41.3047H43.5523"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ITIcon',
});
</script>
