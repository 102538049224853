var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18 27.5C13.32 27.5 4 29.84 4 34.5V38H32V34.5C32 29.84 22.68 27.5 18 27.5ZM8.68 34C10.36 32.84 14.42 31.5 18 31.5C21.58 31.5 25.64 32.84 27.32 34H8.68ZM18 24C21.86 24 25 20.86 25 17C25 13.14 21.86 10 18 10C14.14 10 11 13.14 11 17C11 20.86 14.14 24 18 24ZM18 14C19.66 14 21 15.34 21 17C21 18.66 19.66 20 18 20C16.34 20 15 18.66 15 17C15 15.34 16.34 14 18 14ZM32.08 27.62C34.4 29.3 36 31.54 36 34.5V38H44V34.5C44 30.46 37 28.16 32.08 27.62ZM30 24C33.86 24 37 20.86 37 17C37 13.14 33.86 10 30 10C28.92 10 27.92 10.26 27 10.7C28.26 12.48 29 14.66 29 17C29 19.34 28.26 21.52 27 23.3C27.92 23.74 28.92 24 30 24Z",
          fill: "#E69A35",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }