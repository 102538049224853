import { DataTableHeader } from 'vuetify';

import { FastTrackRequestTableHeadersTransations, RequestStatuses } from '@/common/types';
import {
  CommonRequestTableProps,
  CommonRequestTableTranslations,
} from '@/common/types/verification-request/verification-request';

export const monthlyReportsTableHeaders = (isAdmin: boolean): DataTableHeader[] => [
  ...(isAdmin ? [{ text: 'Service provider', value: 'serviceProvider', sortable: true }] : []),
  { text: 'Report month', value: 'month', sortable: true },
  { text: 'Report status', value: 'status', sortable: false },
  { text: isAdmin ? 'Invoice approved date' : 'Invoice send date', value: 'invoiceSendDate', sortable: true },
  { text: 'Invoice status', value: 'invoiceStatus', sortable: true },
  { text: '', value: 'actions', sortable: false, width: '300px' },
];

export const occupationRelatedEntityTableHeaders = (authorHeader: boolean): DataTableHeader[] => [
  { text: 'ID', value: 'id', sortable: false },
  { text: 'Name [AR]', value: 'nameArabic', sortable: false },
  { text: 'Name [EN]', value: 'nameEnglish', sortable: false },
  ...(authorHeader ? [{ text: 'Author', value: 'author', sortable: false }] : []),
  { text: 'Creation date', value: 'createdAt', sortable: false },
  { text: 'Last update', value: 'updatedAt', sortable: false },
  { text: '', value: 'actions', sortable: false, width: '140px' },
];

export const commonRequestTableHeaders = (
  tableProps: CommonRequestTableProps,
  isServiceProviderManager: boolean,
  translations: CommonRequestTableTranslations,
): DataTableHeader[] => {
  const {
    requestId: requestIdTranslation,
    passportNo: passportNoTranslation,
    nationality: nationalityTranslation,
    status: statusTranslation,
    receivedDate: receivedDateTranslation,
    assignee: assigneeTranslation,
    paymentStatus: paymentStatusTranslation,
    sla: slaTranslation,
    ccor: ccorTranslation,
    type: typeTranslation,
    major: majorTranslation,
    educationLevel: educationLevelTranslation,
  } = translations ?? {};

  const columns: DataTableHeader[] = [
    { text: requestIdTranslation, value: 'requestId', sortable: false, width: '100px' },
    { text: 'Name', value: 'name', sortable: false },
    { text: passportNoTranslation, value: 'passportNumber', sortable: false, width: '80px' },
    { text: nationalityTranslation, value: 'nationality', sortable: false, width: '100px' },
    { text: typeTranslation, value: 'type', sortable: false, width: '100px' },
    { text: statusTranslation, value: 'status', sortable: false },
    { text: tableProps.updatedColumnName!, value: 'statusUpdate', sortable: false },
    { text: receivedDateTranslation, value: 'sendDate', sortable: false },
  ];

  if (tableProps.admin) {
    const hide = ['name'];
    const filteredColumns = [...columns.filter((e) => !hide.includes(e.value))];
    columns.length = 0;
    columns.push(...filteredColumns);
    columns.splice(3, 0, { text: assigneeTranslation, value: 'assignee', sortable: false, width: '100px' });

    if (tableProps.isDone) {
      columns.splice(6, 0, { text: majorTranslation, value: 'majorStatusCheck', sortable: false, width: '150px' });
      columns.splice(7, 0, {
        text: educationLevelTranslation,
        value: 'educationLevelStatusCheck',
        sortable: false,
        width: '150px',
      });
    }

    if (tableProps.tableKey === RequestStatuses.WITHDRAWN) {
      columns.splice(6, 0, { text: paymentStatusTranslation, value: 'paymentStatus', sortable: false, width: '150px' });
    }
  }

  if (isServiceProviderManager) {
    columns.splice(6, 0, { text: 'Verifier', value: 'verifier', sortable: false });
  }

  if (tableProps.hasSlaHeader) {
    const column = tableProps.admin ? 6 : 7;

    columns.splice(column, 0, { text: slaTranslation, value: 'sla', sortable: false, width: '90px' });
  }

  if (tableProps.multiStatusTable) {
    columns.splice(3, 0, { text: ccorTranslation, value: 'currentResidenceLocation', sortable: false });
  }

  if (tableProps.hideDetails) {
    const hide = ['status', 'statusUpdate', 'serviceProviderId'];
    return columns.filter((e) => !hide.includes(e.value));
  }

  if (tableProps.isDone) {
    return columns.filter((e) => !(e.value === 'sendDate'));
  }

  return columns;
};
export const fastTrackRequestTableHeaders = (
  isImmediateTable: boolean,
  translations: FastTrackRequestTableHeadersTransations,
): DataTableHeader[] => {
  const {
    requestId: requestIdTranslation,

    nationality: nationalityTranslation,
    passportNo: passportNoTranslation,
    entityName: entityNameTranslation,

    entityNameEnglish: entityNameEnglishTranslation,
    entityNameArabic: entityNameArabicTranslation,
    entityNo: entityNoTranslation,

    createdAt: createdAtTranslation,
    updatedAt: updatedAtTranslation,
    status: statusTranslation,
  } = translations ?? {};

  const columns: DataTableHeader[] = [
    { text: requestIdTranslation, value: 'requestId', sortable: false, width: '100px' },

    { text: createdAtTranslation, value: 'createdAt', sortable: true },
    { text: updatedAtTranslation, value: 'updatedAt', sortable: true },
    { text: statusTranslation, value: 'status', sortable: false },
  ];

  if (isImmediateTable) {
    const immediateHeaders = [
      { text: nationalityTranslation, value: 'nationality', sortable: false },
      { text: passportNoTranslation, value: 'passportNumber', sortable: false },
      { text: entityNameTranslation, value: 'entityName', sortable: false },
    ];
    columns.splice(1, 0, ...immediateHeaders);
  } else {
    const majorEntityHeaders = [
      { text: entityNameEnglishTranslation, value: 'entityNameEnglish', sortable: false },
      { text: entityNameArabicTranslation, value: 'entityNameArabic', sortable: false },
      { text: entityNoTranslation, value: 'entityNo', sortable: false },
    ];
    columns.splice(1, 0, ...majorEntityHeaders);
  }
  return columns;
};

export const membersTableHeaders = (
  {
    isAdmin,
    hideCompanyColumn,
    isAdminOrHrsdMembersTable,
  }: { isAdmin: boolean; hideCompanyColumn: boolean; isAdminOrHrsdMembersTable: boolean },
  translations: {
    member: string;
    role: string;
    status: string;
    invitationDate: string;
    lastActive: string;
  },
): DataTableHeader[] => {
  const {
    member: memberTranslation,
    role: roleTranslation,
    status: statusTranslation,
    invitationDate: invitationDateTranslation,
    lastActive: lastActiveTranslation,
  } = translations ?? {};

  const tableHeaders = [
    { text: memberTranslation, value: 'member', sortable: true },
    ...(isAdmin ? [{ text: roleTranslation, value: 'role', sortable: true }] : []),
    ...(hideCompanyColumn ? [] : [{ text: 'Company', value: 'company', sortable: true }]),
    { text: statusTranslation, value: 'status', sortable: false },
    { text: invitationDateTranslation, value: 'createdAt', sortable: false },
    ...(isAdminOrHrsdMembersTable ? [{ text: lastActiveTranslation, value: 'lastActive', sortable: true }] : []),
    { text: '', value: 'remove', sortable: false, width: '120px' },
  ];

  return tableHeaders;
};

export const countriesTableHeaders: DataTableHeader[] = [
  { text: 'Key', value: 'key', sortable: false, width: '75px' },
  { text: 'Nationality [ENG]', value: 'nameEnglish', sortable: false, width: '155px' },
  { text: 'Nationality [AR]', value: 'nameArabic', sortable: false, width: '155px' },
  { text: 'Country code', value: 'countryCode', sortable: false, width: '125px' },
  { text: 'Country [ENG]', value: 'countryNameEnglish', sortable: false, width: '155px' },
  { text: 'Country [AR]', value: 'countryNameArabic', sortable: false, width: '155px' },
  { text: 'Mandated', value: 'isMandated', sortable: false, width: '125px' },
  { text: '', value: 'actions', sortable: false },
];

export const instituteFeesTableHeaders: DataTableHeader[] = [
  { text: 'Institute [EN]', value: 'nameEnglish', sortable: false },
  { text: 'Location', value: 'country', sortable: false },
  { text: 'Edu. level', value: 'educationLevel', sortable: false },
  { text: 'Major', value: 'major', sortable: false },
  { text: 'Fee', value: 'fee', sortable: false },
  { text: 'Author', value: 'author', sortable: false },
  { text: 'Creation date', value: 'createdAt', sortable: false },
  { text: 'Last update', value: 'updatedAt', sortable: false },
  { text: '', value: 'actions', sortable: false },
];

export const slaTableHeaders: DataTableHeader[] = [
  { text: 'ID', value: 'id', width: '100px', sortable: false },
  { text: 'Request ID', value: 'requestId', sortable: false },
  { text: 'User ID', value: 'userId', sortable: false },
  { text: 'Timestamp Start', value: 'timestampStart', sortable: false },
  { text: 'Timestamp Stop', value: 'timestampStop', sortable: false },
  { text: 'Interval seconds', value: 'intervalSeconds', sortable: false },
  { text: 'Status', value: 'status', sortable: false },
  { text: 'Actions', value: 'actions', sortable: false },
];

export const serviceProvidersTableHeaders: DataTableHeader[] = [
  { text: 'Service provider', value: 'name', width: '150px', sortable: false },
  { text: 'Country', value: 'country', width: '110px', sortable: false },
  { text: 'Establishment nr', value: 'establishmentNumber', width: '100px', sortable: false },
  { text: 'VR Type', value: 'verificationTypes', width: '100px', sortable: false },
  { text: 'Active verifiers', value: 'activeVerifiers', width: '60px', sortable: false },
  { text: 'Pending', value: 'pendingRequestCount', width: '60px', sortable: false },
  { text: 'In progress', value: 'inProgressRequestCount', width: '60px', sortable: false },
  { text: 'Status', value: 'status', width: '140px', sortable: false },
  { text: '', value: 'actions', sortable: false },
];

export const occupationTableHeaders: DataTableHeader[] = [
  { text: 'ID', value: 'id', sortable: false },
  { text: 'Key', value: 'key', sortable: false },
  { text: 'Name [AR]', value: 'nameArabic', sortable: false, width: '140px' },
  { text: 'Name [EN]', value: 'nameEnglish', sortable: false, width: '140px' },
  { text: 'Category', value: 'category', sortable: false },
  { text: 'Creation date', value: 'createdAt', sortable: false },
  { text: 'Last update', value: 'updatedAt', sortable: false },
  { text: '', value: 'actions', sortable: false, width: '120px' },
];

export const categoriesTableHeaders: DataTableHeader[] = [
  { text: 'ID', value: 'id', sortable: false },
  { text: 'Key', value: 'key', sortable: false },
  { text: 'Name [AR]', value: 'nameArabic', sortable: false },
  { text: 'Name [EN]', value: 'nameEnglish', sortable: false },
  { text: 'Creation date', value: 'creationDate', sortable: false },
  { text: 'Last update', value: 'lastUpdated', sortable: false },
  { text: '', value: 'actions', sortable: false, width: '140px' },
];

export const adminSlaReportsTableHeaders: DataTableHeader[] = [
  { text: 'Company name', value: 'companyName', width: '170px', sortable: false },
  { text: 'Report month', value: 'createdAt', width: '120px', sortable: false },
  { text: 'Done requests', value: 'doneRequest', width: '120px', sortable: false },
  { text: 'Average time', value: 'averageDays', width: '200px', sortable: false },
];

export const certificatesTableHeader: DataTableHeader[] = [
  { text: 'Passport number', value: 'passportNumber', width: '110px', sortable: false },
  { text: 'Country of residence', value: 'countryOfResidence', width: '120px', sortable: false },
  { text: 'Nationality', value: 'nationality', width: '120px', sortable: false },
  { text: 'Email', value: 'email', width: '150px', sortable: false },
  { text: 'Last active date', value: 'lastActiveDate', width: '120px', sortable: false },
  { text: 'Major', value: 'majorName', width: '110px', sortable: false },
  { text: 'Education level', value: 'educationLevel', width: '120px', sortable: false },
  { text: 'Certificate serial number', value: 'certificateSerialNumber', width: '170px', sortable: false },
  { text: '', value: 'actions', sortable: false },
];

export const paymentHistoryTableHeaders: DataTableHeader[] = [
  { text: 'Request ID', value: 'simpleId', sortable: false },
  { text: 'Transaction ID', value: 'referenceNumber', sortable: false, width: '121px' },
  { text: 'Amount', value: 'amount', sortable: false },
  { text: 'Passport no.', value: 'candidatePassportNumber', sortable: false },
  { text: 'CCoR', value: 'countryOfResidence', sortable: false, width: '100px' },
  { text: 'Assignee', value: 'serviceProviderName', sortable: false, width: '180px' },
  { text: 'Payment', value: 'status', sortable: false },
  { text: 'Transaction Date', value: 'transactionDate', sortable: false },
  { text: '', value: '', sortable: false, width: '120px' },
];

export const trialCountriesTableHeaders: DataTableHeader[] = [
  { text: 'Country', value: 'countryName', width: '120px' },
  { text: 'Start', value: 'startAt', width: '120px' },
  { text: 'Finished', value: 'endAt', width: '120px' },
  { text: 'Status', value: 'status', width: '100px' },
  { text: 'Free verifications', value: 'total', width: '160px' },
  { text: 'Consumed', value: 'used', width: '120px' },
  { text: 'Remaining', value: 'remaining', width: '120px' },
  { text: '', value: 'actions', sortable: false },
];
