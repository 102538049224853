<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M36.6084 16.3066H22.7209C21.1869 16.3066 19.9434 17.5502 19.9434 19.0841V41.3041C19.9434 42.8381 21.1869 44.0816 22.7209 44.0816H36.6084C38.1423 44.0816 39.3859 42.8381 39.3859 41.3041V19.0841C39.3859 17.5502 38.1423 16.3066 36.6084 16.3066Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.5915 24.6387L26.8877 30.1937H32.4427L28.7389 35.7487"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EnergyIcon',
});
</script>
