var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "61",
        viewBox: "0 0 60 61",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "30", cy: "30.5293", r: "30", fill: "#E69A35" },
      }),
      _c("path", {
        attrs: {
          d: "M37.7195 40.1937L35.2198 28.8059L40.0804 23.9453C42.1635 21.8622 42.8579 19.0847 42.1635 17.6959C40.7748 17.0016 37.9973 17.6959 35.9141 19.7791L31.0535 24.6397L19.6658 22.1399C18.9714 22.0011 18.4159 22.2788 18.1381 22.8343L17.7215 23.5287C17.4438 24.2231 17.5826 24.9174 18.1381 25.3341L25.4985 30.1947L22.721 34.3609H18.5548L17.166 35.7497L21.3323 38.5272L24.1098 42.6934L25.4985 41.3047V37.1384L29.6648 34.3609L34.5254 41.7213C34.942 42.2768 35.6364 42.4157 36.3308 42.1379L37.0251 41.8602C37.5806 41.4436 37.8584 40.8881 37.7195 40.1937V40.1937Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }