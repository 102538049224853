import { RequestStatuses } from '@/common/types/status';
import { FileDetails } from '@/common/types/upload';

export enum LogType {
  START_VERIFICATION = 'startVerification',
  REJECT_HOLD = 'rejectHold',
  CONSENT_WITHDRAWN = 'consentWithdrawn',
  REQUEST_CREATE = 'requestCreate',
  REQUEST_FOR_UPDATE = 'requestForUpdate',
  REQUEST_HOLD = 'requestHold',
  REQUEST_RESUME = 'requestResume',
  REQUEST_REASSIGN = 'requestReassign',
  REQUEST_VERIFIED = 'requestVerified',
  REQUEST_REJECTED = 'requestUnableToVerify',
  REQUEST_APPEALED = 'requestAppealed',
  REQUEST_UPDATED = 'requestUpdated',
  REQUEST_TO_IN_PROGRESS = 'requestToInProgress',
  ADDITIONAL_PAYMENT_REQUESTED = 'requestAdditionalPaymentRequested',
}

export interface LogDetails {
  oldRequestStatus?: RequestStatuses;
  newRequestStatus?: RequestStatuses;
  comment?: string;
  attachments?: FileDetails[];
  fieldsReturned?: string[];
  oldVerifier?: { firstName: string; lastName: string };
  newVerifier?: { firstName: string; lastName: string };
  requestReport?: FileDetails;
}

export interface Log {
  id: string;
  type: LogType;
  createdDate: string;
  createdBy: string;
  details: LogDetails;
  description?: string;
  createdAt?: {
    date?: string;
  };
  data?: {
    revokeComment: string;
  };
}

export interface LogHelper {
  helperKey: string;
  icon: string;
  iconColor: string;
}

export interface LogTypeMap {
  [key: string]: LogHelper;
}
