export interface ValidCertificateData {
  certificateNumber: string;
  educationLevel: string;
  issueAt: string;
  laborName: string;
  major: string;
  passportNumber: string;
}

export interface CertificateListItem {
  id: string;
  certificateSerialNumber: string;
  countryOfResidence: string;
  educationLevel: string;
  email: string;
  fileId: string;
  lastActiveDate: string;
  majorName: string;
  nationality: string;
  passportNumber: string;
}

export interface CertificateInformation {
  academicCertificateId: string;
  certificateSerialNumber: string;
  educationLvl: string;
  issuingDate: string;
  major: string;
  verificationRequestCertificateId: string;
  academicCertificateName: string;
  verificationRequestCertificateName: string;
}

export interface CertificatePersonalInformation {
  countryOfResidence: string;
  email: string;
  firstName: string;
  lastActiveDate: string;
  lastName: string;
  nationality: string;
  passportNumber: string;
  phoneNumber: string;
}

export interface CertificateDetails {
  personalDetails: CertificatePersonalInformation;
  certificate: CertificateInformation;
}

export enum CertificateActions {
  CANCEL = 'cancel',
  REGENERATE = 'regenerate'
}