var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M40 14H32V10L28 6H20L16 10V14H8C5.8 14 4 15.8 4 18V28C4 29.5 4.8 30.76 6 31.46V38C6 40.22 7.78 42 10 42H38C40.22 42 42 40.22 42 38V31.44C43.18 30.74 44 29.46 44 28V18C44 15.8 42.2 14 40 14ZM20 10H28V14H20V10ZM8 18H40V28H30V22H18V28H8V18ZM26 30H22V26H26V30ZM38 38H10V32H18V34H30V32H38V38Z",
          fill: "#E69A35",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }