import { RequestDetailsKeys } from '../types';

export const draft = [{ name: 'Draft', nameArabic: 'مسودة', value: 'drafted', color: '#757575' }];

export const unpaid = [{ name: 'Unpaid', nameArabic: 'في انتظار الدفع', value: 'unpaid', color: '#ED6C02' }];

export const paymentPending = [
  { name: 'Payment pending', nameArabic: 'انتظار الدفع', value: 'payment_pending', color: '#ED6C02' },
];

export const pending = [{ name: 'Pending', nameArabic: 'قيد الانتظار', value: 'pending', color: '#2196F3' }];

export const inProgress = [{ name: 'In progress', nameArabic: 'تحت الإجراء', value: 'in_progress', color: '#2196F3' }];

export const onHold = [{ name: 'On-hold', nameArabic: 'في الانتظار', value: 'on_hold', color: '#757575' }];

export const forUpdate = [{ name: 'For Update', nameArabic: 'للتحديث', value: 'returned', color: '#ED6C02' }];

export const updated = [{ name: 'Updated', nameArabic: 'محدث', value: 'updated', color: '#2196F3' }];

export const withdrawn = [{ name: 'Withdrawn', nameArabic: 'منسحب', value: 'withdrawn', color: '#757575' }];

export const paid = [{ name: 'Paid', nameArabic: 'دفع', value: 'paid', color: '#ED6C02' }];

export const additionalFee = [
  {
    name: 'Additional fee requested',
    nameArabic: 'رسوم إضافية مطلوبة',
    value: 'additional_payment_requested',
    color: '#ED6C02',
  },
];

export const inProgressAdminTable = [...inProgress, ...updated, ...forUpdate, ...additionalFee];

export const done = [
  { name: 'Verified', nameArabic: 'تم التحقق بنجاح', value: 'accepted', color: '#4CAF50' },
  { name: 'Unable to verify', nameArabic: 'غير قادر على التحقق', value: 'unable_to_verify', color: '#F44336' },
  { name: 'Refunded', nameArabic: 'ردها', value: 'refunded', color: '#ED6C02' },
];

export const statuses = [...pending, ...inProgress, ...updated, ...forUpdate, ...done, ...withdrawn, ...additionalFee];

export const allStatuses = [...statuses, ...draft, ...paymentPending, ...onHold, ...unpaid, ...additionalFee];

export const allPaymentStatuses = [...draft, ...unpaid, ...paid, ...paymentPending];

export const candidateDashboardStatuses = [
  ...draft,
  ...withdrawn,
  ...onHold,
  ...done,
  ...inProgress,
  ...forUpdate,
  ...unpaid,
];

export default statuses;

// VerificationRequestSummary keys
export const CompatibilityKeys: RequestDetailsKeys[] = [
  { name: 'majorStatusCheck', adminOnly: false },
  { name: 'educationLevelStatusCheck', adminOnly: false },
];

export const PersonalDetailsKeys: RequestDetailsKeys[] = [
  { name: 'firstName', adminOnly: false },
  { name: 'secondName', adminOnly: false },
  { name: 'lastName', adminOnly: false },
  { name: 'alternateName', adminOnly: false },
  { name: 'formerMarriedName', adminOnly: false },
  { name: 'maidenName', adminOnly: false },
  { name: 'email', adminOnly: true },
  { name: 'suffix', adminOnly: false },
  { name: 'mothersName', adminOnly: false },
  { name: 'gender', adminOnly: false },
  { name: 'nationality', adminOnly: false },
  { name: 'birthAt', adminOnly: false },
  { name: 'countryOfResidence', adminOnly: false },
  { name: 'passportNumber', adminOnly: false },
  { name: 'passportExpireAt', adminOnly: false },
  { name: 'state', adminOnly: false },
  { name: 'currentResidenceLocation', adminOnly: false },
  { name: 'requestReason', adminOnly: true },
];

export const EducationDataKeys: RequestDetailsKeys[] = [
  { name: 'educationInstitute', adminOnly: false },
  { name: 'college', adminOnly: false },
  { name: 'instituteStreet', adminOnly: false },
  { name: 'instituteBuildingNumber', adminOnly: false },
  { name: 'instituteCity', adminOnly: false },
  { name: 'instituteState', adminOnly: false },
  { name: 'institutePostalCode', adminOnly: false },
  { name: 'instituteCountry', adminOnly: false },
  { name: 'institutePhoneNumber', adminOnly: false },
  { name: 'instituteWebAddress', adminOnly: false },
  { name: 'educationalLevel', adminOnly: false },
  { name: 'majorName', adminOnly: false },
  { name: 'startDateOfEnrollment', adminOnly: false },
  { name: 'endDateOfEnrollment', adminOnly: false },
  { name: 'nameOnRecord', adminOnly: false },
  { name: 'studentId', adminOnly: false },
  { name: 'studentEmail', adminOnly: false },
  { name: 'awardName', adminOnly: false },
  { name: 'awardIssuedAt', adminOnly: false },
];

export const EmploymentKeys: RequestDetailsKeys[] = [
  { name: 'occupation', subname: 'name', adminOnly: false },
  { name: 'occupation', subname: 'key', adminOnly: true },
  { name: 'yearsOfExperience', adminOnly: false },
  { name: 'employer', adminOnly: false },
];

export type CandidateProfileFormKeys =
  | 'firstName'
  | 'secondName'
  | 'lastName'
  | 'alternateName'
  | 'maidenName'
  | 'formerMarriedName'
  | 'suffix'
  | 'mothersName'
  | 'gender'
  | 'nationality'
  | 'birthAt'
  | 'countryOfResidence'
  | 'passportNumber'
  | 'passportExpireAt';

export type RequestTableKeys = 'all' | 'pending' | 'inProgress' | 'done' | 'withdrawn' | 'fastTrack';
