/* eslint-disable camelcase */
import { Notification } from '@/common/types/notifications';
import { List } from '@/common/types/verification-form';

import { Language } from './languages';
import { Role } from './members';

enum CommonEndpoints {
  UnregisteredOtp = '/otp',
  UserOtp = '/user/otp',
  ChangePassword = '/user/change-password',
  Login2Fa1Step = '/authenticate',
  Login2Fa2Step = '/otp/check',
  RefreshToken = '/authentication/refresh-token',
  UserData = '/user/me',
  FileUpload = '/file/upload',
  FileDelete = '/file/delete',
  FileDownload = '/file/download',
  FileFind = '/file/find',
  FileDetails = 'file/details',
  Notifications = '/notifications',
  Logs = '/logs',
  NotificationsRead = '/notification/update',
  Logout = '/user/logout',
  GetJobStatus = '/messenger-job/status',
  JobSeen = '/messenger-job/seen',
  SlaDetails = '/sla-details',
  Reports = '/reports',
  Invoices = '/invoices',
  FillValues = '/dev-simple-fill-vr',
  CandidateNotificationsUpdate = '/notification/update',
}

enum CandidateEndpoints {
  CandidateBase = '/candidates',
  CandidateRegistration = '/candidate/register',
  CandidateVerificationRequest = '/candidate/verification-request',
  CandidateVerificationRequestDetails = '/candidate/verification-request/details',
  CandidateSendVerificationReq = '/candidate/verification-request/create',
  CandidateCreateDraft = '/candidate/verification-request/draft',
  CandidateUpdateDraft = '/candidate/verification-request/update',
  CandidatePaymentCheckout = '/verification-request/payment/checkout',
  EducationInstitute = '/candidate/education-institute',
  CandidateWithdrawConsent = '/candidate/verification-request/withdraw-consent',
  Email = '/candidate/email',
  PhoneNumber = '/candidate/phone',
  DeleteAccount = '/candidate/remove-account',
  PersonalDataPresence = '/candidate/personal-data-presence',
  FileScan = '/candidate/passport-scan',
  ConfirmOcrData = '/candidate/fill-data',
  CheckPassportUniqueness = '/candidate/check-passport',
  GetCountryData = '/candidate/nationalities',
  EducationalLevelsList = '/candidate/education-levels',
  CandidateMajorsList = '/candidate/majors',
  CandidateLocationByIp = '/candidate/current-location',
  CandidateMergeSignature = '/candidate/sign-consent',
  CandidateOccupations = '/candidate/occupations',
  CandidateEntityNames = '/major-entities-names',
}

enum ServiceProviderEndpoints {
  ServiceProvider = '/service-provider',
  ServiceProviders = '/service-providers',
  InviteServiceProvider = '/service-providers/invite',
  ReInviteServiceProvider = '/service-providers/invite/resend',
  SPRegistration = '/service-provider/register',
  SPRegistrationEmployee = '/service-provider/register-employee',
  SPSettings = '/service-provider/settings',
  SPEmployeeList = '/service-provider/employee',
  SPSendReport = '/service-provider-employee/reports/update',
  ServiceProviderVerificationRequest = '/service-provider/verification-request',
  CompanyList = '/service-provider/company',
  Member = '/service-provider/member',
  ServiceProviderVerificationRequestUpdate = '/service-provider/verification-request',
  ServiceProviderCheckInvitationLink = '/service-provider/check-invite',
  DeactivateVerifier = '/service-provider/user-deactivate',
  ActivateVerifier = '/service-provider/user-activate',
  RemoveVerifierInvitation = '/service-provider/member',
  ServiceProviderReassignVerifier = '/service-provider/verification-request/reassign',
  SlaActions = '/service-provider/sla',
  CompanyDetails = '/service-provider/company-details',
  ServiceProviderReports = '/service-provider/reports',
}

enum UserEndpoints {
  UsersListBase = '/users',
  UserSendChangePhoneOtp = '/user/send-change-phone-token',
  UserChangePhone = '/user/change-phone',
  RequestResetPasswordToken = '/users/request-reset-password',
  ResetPassword = '/users/reset-password',
}

enum AdminEndpoints {
  AdminRegistration = '/admin/register-admin',
  AdminCheckInvite = '/admin/check-invite',
  AdminRequests = '/admin/verification-request',
  VerificationRequestStatistics = '/verification-request/statistics',
  OccupationCreate = '/admin/occupation/create',
  OccupationList = '/occupations',
  OccupationListWithoutCategories = '/admin/occupation/list-without-category',
  OccupationEdit = '/admin/occupation/edit',
  OccupationDetails = '/admin/occupation',
  CategoryCreate = '/admin/occupation-category/create',
  CategoryList = 'occupation-categories',
  CategoryDetails = '/admin/occupation-category',
  CategoryEdit = '/admin/occupation-category/edit',
  ReassignRequest = '/admin/verifications/change-company',
  EducationLevelList = '/education-levels',
  EducationLevelBase = '/admin/education-level',
  EducationFieldBase = '/admin/education-field',
  EducationInstituteFeesBase = '/education-institute-fees',
  CompetenceList = '/behavioral-competences',
  CompetenceBase = '/admin/behavioral-competence',
  MeritList = '/artistic-merits',
  MeritBase = '/admin/artistic-merit',
  MajorList = '/admin/majors',
  MajorBase = '/admin/major',
  MinorsList = '/admin/minors',
  QualificationsBase = '/admin/qualifications',
  ReassignRole = '/admin/service-provider/change-role',
  ReassignAdminRole = '/admin/change-role',
  PaymentBase = '/admin/payment-transactions',
  PaymentTransactionBase = '/payment-transactions',
  PaymentReport = '/admin/payment-transactions-report',
  SLA = '/admin/sla',
  SLAReport = '/admin/sla-report',
  RevokeSla = '/admin/sla-hold-revoke',
  AdminList = '/admin/invitations',
  AdminMemberAction = '/admin/invitations',
  DeactivateAdmin = '/admin/admin-deactivate',
  ActivateAdmin = '/admin/admin-activate',
  CountryList = '/admin/countries',
  CountryEdit = '/admin/country/edit',
  CountryDetails = '/admin/country',
  AdminServiceProviders = '/admin/service-providers',
  FreeTrials = '/discounts',
}

enum VerificationRequestEndpoints {
  VerificationRequest = '/verification-request',
  VerificationRequestTypes = '/verification-request-types',
  VerificationRequests = '/verification-requests',
  VerificationRequestsFastTrack = '/verification-requests/fast-track',
  VerificationRequestDetails = '/verification-request/details',
  VerificationRequestRejectReasons = '/verification-request/reject-reasons',
  VerificationRequestValidationDetails = '/verification-request/validation-details',
  VerificationRequestsReports = '/verification-request/reports',
}

enum HrsdEndpoints {
  FastTrackImmediate = '/fast-track-immediate-request',
  FastTrackImmediateRequests = '/fast-track-immediate-requests',
  FastTrackImmediateRecord = '/fast-track-immediate-records',
  FastTrackImmediateAvailability = '/fast-track-immediate-availability',
  FastTrackMajorEntity = '/fast-track-major-entity-request',
  FastTrackMajorEntityRequests = '/fast-track-major-entity-requests',
  FastTrackMajorEntityRecord = '/fast-track-major-entity-records',
  FastTrackMajorEntityAvailability = '/fast-track-major-entity-request-availability',
}

export const ApiEndpoints = {
  ...CommonEndpoints,
  ...CandidateEndpoints,
  ...ServiceProviderEndpoints,
  ...AdminEndpoints,
  ...UserEndpoints,
  ...VerificationRequestEndpoints,
  ...HrsdEndpoints,
};

export enum ApiRoles {
  ADMIN = 'admin',
  SP = 'service-provider',
  CANDIDATE = 'candidate',
}

export interface ApiResponse<T> {
  type: string;
  id: string | null;
  attributes: T;
}

export interface LoginPayload {
  username: string;
  password: string;
  key?: string;
  hCaptchaResponse?: string;
  otp?: string;
}

export type LoginResponse = ApiResponse<{
  token: string;
  refresh_token: string;
}>;

export interface RegisterDetailsPayload {
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  countryCode: string;
  otp?: string;
}

export interface PersonalDetailsPayload {
  firstName: string;
  lastName: string;
  birthAt: string;
  nationality: string;
  passportNumber: string;
  countryCode: string;
  phoneNumber: string;
}

export interface OtpPayload {
  countryCode?: string;
  phoneNumber?: string;
  email?: string;
  captcha?: string;
  type: 'sms' | 'email';
}

export type IsFilledType = boolean | null;

export interface TwoFactorAuthData {
  userRoles?: Role[];
  countryCode?: string;
  phoneDigits?: string;
  phoneLength: number;
  isFilled?: IsFilledType;
}

export interface ErrorResponse {
  errorMsg: string;
}

export interface AccessDataPayload {
  email?: string;
  password: string;
  passwordRepeat: string;
  inviteToken: string;
}

export interface PersonalDataPayload {
  firstName: string;
  lastName: string;
}

export interface SPAccessDataPayload {
  serviceProviderEmployeeEmail?: string;
  serviceProviderEmployeePassword: string;
  serviceProviderEmployeeConfirmPassword: string;
  inviteToken: string;
}

export interface SPPersonalDataPayload {
  serviceProviderEmployeeFirstName: string;
  serviceProviderEmployeeLastName: string;
}

export interface SPCompanyDataPayload {
  accountBankName: string;
  accountBankSwift: string;
  accountBankIban: string;
  serviceProviderCompanyName: string;
  serviceProviderCompanyCountry: string;
  serviceProviderEstablishmentNumber: string;
}

export type RefreshTokenResponse = LoginResponse['attributes'];
export type SPRegistrationDataPayload = SPAccessDataPayload & SPPersonalDataPayload & SPCompanyDataPayload;
export type AdminRegistrationDataPayload = AccessDataPayload & PersonalDataPayload;

export interface TokenData {
  iat: number;
  exp: number;
  roles: Role[];
  email: string;
  personalDataPresence?: boolean;
}

export interface OtpLoginResponse {
  token: string;
}

export interface UpdateAccountData {
  firstName?: string | null;
  lastName?: string | null;
  oldPassword?: string | null;
  newPassword?: string | null;
  confirmNewPassword?: string | null;
  notificationsLanguage?: string | null;
}

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface CandidateData {
  firstName: string | null;
  secondName: string | null;
  lastName: string | null;
  alternateName: string | null;
  maidenName: string | null;
  formerMarriedName: string | null;
  suffix: string | null;
  mothersName?: string | null;
  gender: string | null;
  nationality: string | null;
  birthAt: string | null;
  countryOfResidence: string | null;
  passportNumber: string | null;
  passportExpireAt: string | null;
  isProfileErasable?: boolean;
}

export interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Role[];
  mainRole: Role;
  passportNumber: string;
  nationality: string;
  birthAt: string;
  secondName: string | null;
  alternateName: string | null;
  maidenName: string | null;
  formerMarriedName: string | null;
  suffix: string | null;
  mothersName: string | null;
  gender: string | null;
  countryOfResidence: string | null;
  passportExpireAt: string | null;
  notificationsLanguage: string;
  notifications: List<Notification> | null;
  personalDataPresence: boolean;
  userLanguage: Language;
  phoneNumber: string;
}

export interface FastTrackRequestTableFilters {
  statuses: string[] | null;
  search: string | null;
  order: 'ASC' | 'DESC';
  sortBy: 'createdAt' | 'updatedAt';
}

export interface RequestTableFilters {
  verifierRole: string | null;
  country: string | null;
  status: string[] | null;
  serviceProviderId: string | null;
  serviceProviderEmployeeId: string | null;
  search: string | null;
  majorStatus: string | null;
  educationLevelStatus: string | null;
  occupation: string | null;
  paymentStatus: string[] | null;
  receivedDateFrom: string | null;
  receivedDateTo: string | null;
  updatedDateFrom: string | null;
  updatedDateTo: string | null;
}

export interface AdminReportsTableFilters {
  search: string | null;
  monthFrom: string | null;
  monthTo: string | null;
}

export interface DashboardRequestListFilters {
  search: string;
  status: string[];
}

export interface BaseTableFilters {
  search: string;
  status: string;
}

export interface BaseMultiselectItem {
  id: string;
}

export interface Company extends BaseMultiselectItem {
  name: string;
}

export interface User {
  firstName: string;
  lastName: string;
  id: string;
}

export interface Member {
  id: string;
  email: string;
  roles: Role[];
  employeeId: string;
  createdAt: Date;
  lastActive: Date;
  user: User | null;
  company: Company | null;
  status: string;
}

export interface MemberTableFilters {
  search: string;
  role: string;
  company: string;
  status: string;
}

export interface Statistics {
  verified: number;
  inProgress: number;
  forUpdate: number;
  unableToVerify: number;
  pending: number;
  averageSecondsToClose: number;
}

export interface PatchUpdateVerificationRequest {
  comment?: string;
  status?: string;
  reportFileId?: string | null;
}

export interface ResourceAvailability {
  status: boolean;
}

export interface Major {
  id: number;
  key?: string;
  nameArabic: string;
  nameEnglish: string;
}

export interface ResetPassword {
  token: string;
  password: string;
}

export interface MemberFilters {
  search?: string;
  roles?: Role[];
  company?: string;
  status: string;
}

export interface OccupationFilters {
  search: string;
}

export interface EducationInstituteFeesFilters {
  search: string;
}

export enum JobDataStatuses {
  RUNNING = 'running',
  COMPLETED = 'completed',
}

export interface JobData {
  selectedRecords: number;
  affectedRecords: number;
  isSeenStatus: boolean;
}

export interface JobDetails {
  id: string;
  jobName: string;
  status: string;
  data: JobData;
  createdAt: string;
  doneAt: string;
}

export interface CertificateValidityPayload {
  certificateNumber: string;
  passportNumber: string;
  captcha: string;
}

export interface CertificateListParams {
  passportNumber: null | string;
  certificateNumber: null | string;
  major: null | string;
  educationLevel: null | string;
  countryOfResidence: null | string;
  nationality: null | string;
}
