var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "61",
        viewBox: "0 0 60 61",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "30", cy: "30.5293", r: "30", fill: "#E69A35" },
      }),
      _c("path", {
        attrs: {
          d: "M24.1098 32.9716C28.7118 32.9716 32.4423 29.2411 32.4423 24.6391C32.4423 20.0372 28.7118 16.3066 24.1098 16.3066C19.5079 16.3066 15.7773 20.0372 15.7773 24.6391C15.7773 29.2411 19.5079 32.9716 24.1098 32.9716Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.1222 27.9297C39.435 28.4191 40.6032 29.2317 41.5187 30.2922C42.4343 31.3528 43.0676 32.6271 43.3602 33.9972C43.6528 35.3674 43.5951 36.7892 43.1926 38.1312C42.79 39.4732 42.0556 40.6921 41.0572 41.675C40.0588 42.6579 38.8287 43.3733 37.4806 43.7548C36.1325 44.1364 34.7099 44.1718 33.3444 43.8579C31.979 43.544 30.7148 42.8908 29.6686 41.9588C28.6225 41.0269 27.8283 39.8461 27.3594 38.5258",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.7217 21.8613H24.1104V27.4163",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.2059 32.8047L37.178 33.7907L33.2617 37.707",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }