<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M40.7758 37.1377H43.5533V32.4992C43.5539 31.7695 43.4107 31.0469 43.1319 30.3725C42.8531 29.6982 42.4442 29.0853 41.9285 28.5691L39.3871 26.0277H32.4433V37.1377H33.8321M26.8883 37.1377H32.4433V20.4727H15.7783V37.1377H19.9446H26.8883Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.4162 41.3051C25.3337 41.3051 26.8881 39.7507 26.8881 37.8332C26.8881 35.9157 25.3337 34.3613 23.4162 34.3613C21.4987 34.3613 19.9443 35.9157 19.9443 37.8332C19.9443 39.7507 21.4987 41.3051 23.4162 41.3051Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.3029 41.3051C39.2204 41.3051 40.7748 39.7507 40.7748 37.8332C40.7748 35.9157 39.2204 34.3613 37.3029 34.3613C35.3855 34.3613 33.8311 35.9157 33.8311 37.8332C33.8311 39.7507 35.3855 41.3051 37.3029 41.3051Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogisticsIcon',
});
</script>
