<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M24.1104 44.0822C24.8774 44.0822 25.4992 43.4604 25.4992 42.6934C25.4992 41.9265 24.8774 41.3047 24.1104 41.3047C23.3434 41.3047 22.7217 41.9265 22.7217 42.6934C22.7217 43.4604 23.3434 44.0822 24.1104 44.0822Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39.3858 44.0822C40.1528 44.0822 40.7746 43.4604 40.7746 42.6934C40.7746 41.9265 40.1528 41.3047 39.3858 41.3047C38.6188 41.3047 37.9971 41.9265 37.9971 42.6934C37.9971 43.4604 38.6188 44.0822 39.3858 44.0822Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8467 16.377H18.6242L22.3183 33.6252C22.4538 34.2569 22.8052 34.8216 23.3122 35.2221C23.8192 35.6226 24.4499 35.8338 25.0958 35.8195H38.6777C39.3099 35.8184 39.9227 35.6018 40.4151 35.2054C40.9075 34.809 41.2499 34.2565 41.3858 33.6391L43.6772 23.3207H20.1101"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommerceIcon',
});
</script>
