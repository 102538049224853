<template>
  <i
    :class="standardClass"
    data-testId="base-icon"
  >
    {{ parsedIcon.id }}
  </i>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  name: string;
}

const props = defineProps<Props>();

const checkStandard = (customSuffixes: string[], standardSuffix: string) => {
  const suffix = customSuffixes.find((suffix: string) => props.name.endsWith(`_${suffix}`));

  if (suffix) {
    return {
      suffix: standardSuffix,
      id: props.name.substring(0, props.name.indexOf(suffix)),
    };
  }

  return false;
};

const parsedIcon = computed(
  () =>
    checkStandard(['fill', 'filled'], '') ||
    checkStandard(['outline', 'outlined'], 'outlined') || {
      suffix: '',
      id: props.name,
    },
);

const standardClass = computed(() => {
  if (parsedIcon.value.suffix) {
    return `material-icons-${parsedIcon.value.suffix}`;
  }
  return 'material-icons';
});
</script>
