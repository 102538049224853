import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import BaseIcon from '@/components/base/BaseIcon.vue';
import {
  Agriculture,
  Aviation,
  BusinessCenterOutlined,
  Commerce,
  Construction,
  Contracting,
  Culture,
  Education,
  Energy,
  Entertainment,
  Financial,
  Health,
  Industry,
  IT,
  LightbulbOutlined,
  Logistics,
  Media,
  Payment,
  PeopleOutlined,
  Registration,
  Sports,
  Tourism,
  Verification,
  VerifiedUserOutlined,
} from '@/custom-icons/index';

function missingMaterialIcons(ids: string[]) {
  const icons: Record<string, any> = {};

  ids.forEach((id) => {
    ['fill', 'outline'].forEach((suffix) => {
      const name = `${id}_${suffix}`;

      icons[name] = {
        component: BaseIcon,
        props: {
          name,
        },
      };
    });
  });

  return icons;
}

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#158285',
        accent: '#158285',
        secondary: '#F7941D',
        error: '#F44336',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#ED6C02',
        hint: '#8d8d8d',
        gray: '#666666',
        warningFont: '#603e11',
        iconTheme: '#E69A35',
        paccYellow: '#FAB414',
      },
    },
  },
  icons: {
    iconfont: 'md',
    values: {
      agricultureCustom: {
        component: Agriculture,
      },
      aviationCustom: {
        component: Aviation,
      },
      businessCenterOutlinedCustom: {
        component: BusinessCenterOutlined,
      },
      commerceCustom: {
        component: Commerce,
      },
      constructionCustom: {
        component: Construction,
      },
      cultureCustom: {
        component: Culture,
      },
      educationCustom: {
        component: Education,
      },
      energyCustom: {
        component: Energy,
      },
      entertainmentCustom: {
        component: Entertainment,
      },
      financialCustom: {
        component: Financial,
      },
      healthCustom: {
        component: Health,
      },
      ITCustom: {
        component: IT,
      },
      lightbulbOutlinedCustom: {
        component: LightbulbOutlined,
      },
      logisticsCustom: {
        component: Logistics,
      },
      mediaCustom: {
        component: Media,
      },
      peopleOutlinecCustom: {
        component: PeopleOutlined,
      },
      sportsCustom: {
        component: Sports,
      },
      tourismCustom: {
        component: Tourism,
      },
      verifiedUserOutlinedCustom: {
        component: VerifiedUserOutlined,
      },
      contractingCustom: {
        component: Contracting,
      },
      industryCustom: {
        component: Industry,
      },
      verificationCustom: {
        component: Verification,
      },
      registrationCustom: {
        component: Registration,
      },
      paymentCustom: {
        component: Payment,
      },
      ...missingMaterialIcons([
        'assessment',
        'description',
        'groups',
        'help_center',
        'home',
        'logout',
        'notifications',
        'people',
        'person',
        'settings',
      ]),
    },
  },
});
