var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "i",
    { class: _setup.standardClass, attrs: { "data-testId": "base-icon" } },
    [_vm._v(" " + _vm._s(_setup.parsedIcon.id) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }