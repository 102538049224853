import {
  BaseStatus,
  CompatibilityStatus,
  ContractStatuses,
  FastTrackRecordsStatuses,
  FastTrackRequestStatuses,
  InvoiceStatus,
  MemberStatus,
  PaymentStatus,
  ReportStatus,
  RequestStatuses,
  StatusDetails,
} from '../types';

const Colors = {
  success: '#4CAF50',
  warning: '#ED6C02',
  error: '#F44336',
  info: '#2196F3',
  light: '#E0E0E0',
  dark: '#757575',
  default: '#FFFFFF',
};

export const PaymentStatusDetails: StatusDetails<PaymentStatus> = {
  [PaymentStatus.SUCCESS]: {
    translationKey: 'statuses.success',
    color: Colors.success,
  },
  [PaymentStatus.SUSPENDED]: {
    translationKey: 'statuses.suspended',
    color: Colors.warning,
  },
  [PaymentStatus.FAILED]: {
    translationKey: 'statuses.failed',
    color: Colors.error,
  },
  [PaymentStatus.PENDING]: {
    translationKey: 'statuses.pending',
    color: Colors.info,
  },
  [PaymentStatus.ALL]: {
    translationKey: 'statuses.all',
    color: Colors.default,
  },
  [PaymentStatus.NOT_INITIALIZED]: {
    translationKey: 'statuses.not_started',
    color: Colors.default,
  },
  [PaymentStatus.INITIALIZED]: {
    translationKey: 'statuses.initialized',
    color: Colors.default,
  },
};

export const ContractStatusDetails: StatusDetails<ContractStatuses> = {
  [ContractStatuses.ACCEPTED]: {
    translationKey: 'statuses.accepted',
    color: Colors.success,
  },
  [ContractStatuses.REJECTED]: {
    translationKey: 'statuses.rejected',
    color: Colors.error,
  },
  [ContractStatuses.EXPIRED]: {
    translationKey: 'statuses.expired',
    color: Colors.error,
  },
  [ContractStatuses.TERMINATED]: {
    translationKey: 'statuses.terminated',
    color: Colors.error,
  },
  [ContractStatuses.PENDING]: {
    translationKey: 'statuses.pending',
    color: Colors.info,
  },
  [ContractStatuses.NOT_AVAILABLE]: {
    translationKey: 'statuses.not_available',
    color: Colors.warning,
  },
};

export const RequestStatusDetails: StatusDetails<RequestStatuses> = {
  [RequestStatuses.DONE]: {
    translationKey: 'statuses.verified',
    color: Colors.success,
  },
  [RequestStatuses.ACCEPTED]: {
    translationKey: 'statuses.verified',
    color: Colors.success,
  },
  [RequestStatuses.RETURNED]: {
    translationKey: 'statuses.returned',
    color: Colors.warning,
  },
  [RequestStatuses.UNABLE_TO_VERIFY]: {
    translationKey: 'statuses.unableToVerify',
    color: Colors.error,
  },
  [RequestStatuses.IN_PROGRESS]: {
    translationKey: 'statuses.inProgress',
    color: Colors.info,
  },
  [RequestStatuses.ON_HOLD]: {
    translationKey: 'statuses.onHold',
    color: Colors.info,
  },
  [RequestStatuses.UPDATED]: {
    translationKey: 'statuses.updated',
    color: Colors.info,
  },
  [RequestStatuses.DRAFTED]: {
    translationKey: 'statuses.drafted',
    color: Colors.light,
  },
  [RequestStatuses.WITHDRAWN]: {
    translationKey: 'statuses.withdrawn',
    color: Colors.dark,
  },
  [RequestStatuses.ALL]: {
    translationKey: 'statuses.all',
    color: Colors.default,
  },
  [RequestStatuses.PENDING]: {
    translationKey: 'statuses.pending',
    color: Colors.info,
  },
  [RequestStatuses.UNPAID]: {
    translationKey: 'statuses.waitingForPayment',
    color: Colors.warning,
  },
  [RequestStatuses.PAID]: {
    translationKey: 'statuses.paid',
    color: Colors.success,
  },
  [RequestStatuses.PAYMENT_PENDING]: {
    translationKey: 'statuses.paymentPending',
    color: Colors.info,
  },
  [RequestStatuses.REFUNDED]: {
    translationKey: 'statuses.refunded',
    color: Colors.warning,
  },
  [RequestStatuses.ADDITIONAL_PAYMENT_REQUESTED]: {
    translationKey: 'statuses.additionalPayment',
    color: Colors.warning,
  },
};
export const FastTrackRequestAndRecordStatusDetails: StatusDetails<
  FastTrackRequestStatuses & FastTrackRecordsStatuses
> = {
  [FastTrackRequestStatuses.SUBMITTED]: {
    translationKey: 'hrsd.fastTrack.statuses.submitted',
    color: Colors.light,
  },
  [FastTrackRequestStatuses.APPROVED]: {
    translationKey: 'hrsd.fastTrack.statuses.approved',
    color: Colors.success,
  },
  [FastTrackRequestStatuses.PENDING_UPDATES]: {
    translationKey: 'hrsd.fastTrack.statuses.pendingUpdates',
    color: Colors.info,
  },
  [FastTrackRequestStatuses.REJECTED]: {
    translationKey: 'hrsd.fastTrack.statuses.rejected',
    color: Colors.error,
  },
  [FastTrackRequestStatuses.UPDATED]: {
    translationKey: 'hrsd.fastTrack.statuses.updated',
    color: Colors.warning,
  },
  [FastTrackRecordsStatuses.ACTIVE]: {
    translationKey: 'hrsd.fastTrack.statuses.active',
    color: Colors.success,
  },
  [FastTrackRecordsStatuses.CANCELED]: {
    translationKey: 'hrsd.fastTrack.statuses.canceled',
    color: Colors.error,
  },
};

export const CompatibilityStatusDetails: StatusDetails<CompatibilityStatus> = {
  [CompatibilityStatus.COMPATIBLE]: {
    translationKey: 'statuses.compatible',
    color: Colors.success,
  },
  [CompatibilityStatus.INCOMPATIBLE]: {
    translationKey: 'statuses.incompatible',
    color: Colors.error,
  },
  [CompatibilityStatus.UNABLE_TO_VERIFY]: {
    translationKey: 'statuses.unable_to_verify',
    color: Colors.warning,
  },
  [CompatibilityStatus.PENDING]: {
    translationKey: 'statuses.pending',
    color: Colors.info,
  },
};

export const ReportStatusDetails: StatusDetails<ReportStatus> = {
  [ReportStatus.WAITING]: {
    translationKey: 'statuses.for_approval',
    color: Colors.info,
  },
  [ReportStatus.APPROVED]: {
    translationKey: 'statuses.approved',
    color: Colors.success,
  },
};

export const InvoiceStatusDetails: StatusDetails<InvoiceStatus> = {
  [InvoiceStatus.WAITING]: {
    translationKey: 'statuses.for_approval',
    color: Colors.info,
  },
  [InvoiceStatus.FOR_UPDATE]: {
    translationKey: 'statuses.for_update',
    color: Colors.warning,
  },
  [InvoiceStatus.PAID]: {
    translationKey: 'statuses.paid',
    color: Colors.success,
  },
  [InvoiceStatus.NOT_SENT]: {
    translationKey: 'statuses.not_sent',
    color: Colors.light,
  },
  [InvoiceStatus.PAYMENT_PENDING]: {
    translationKey: 'statuses.payment_pending',
    color: Colors.info,
  },
  [InvoiceStatus.RETURNED]: {
    translationKey: 'statuses.returned',
    color: Colors.warning,
  },
};

export const BaseStatusDetails: StatusDetails<BaseStatus> = {
  [BaseStatus.ACTIVE]: {
    translationKey: 'statuses.active',
    color: Colors.success,
  },
  [BaseStatus.INACTIVE]: {
    translationKey: 'statuses.inactive',
    color: Colors.light,
  },
};

export const membersStatuses = [
  { nameEnglish: 'Active', nameArabic: 'نشط', value: MemberStatus.ACTIVE },
  { nameEnglish: 'Deactivated', nameArabic: 'تم التعطيل', value: MemberStatus.DEACTIVATED },
  { nameEnglish: 'Pending', nameArabic: 'قيد الانتظار', value: MemberStatus.PENDING },
];

export { Colors as StatusesColors };
