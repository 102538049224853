import Vue from 'vue'
import Hotjar from 'vue-hotjar'

// eslint-disable-next-line func-names
export default function () {
    Vue.use (Hotjar, {
      id: '2738696',
      isProduction: true,
      snippetVersion: 6
    })
}
