import { Role, RolesMap } from '@/common/types';

export const serviceProviderRoles: RolesMap = {
  [Role.ServiceProviderManager]: { name: 'Manager', value: Role.ServiceProviderManager },
  [Role.ServiceProvider]: { name: 'Verifier', value: Role.ServiceProvider },
};

export const adminRoles: RolesMap = {
  [Role.Admin]: { name: 'Admin', value: Role.Admin },
  [Role.CustomerServiceEditor]: { name: 'Customer service editor', value: Role.CustomerServiceEditor },
  [Role.CustomerServiceViewer]: { name: 'Customer service viewer', value: Role.CustomerServiceViewer },
  [Role.BackOfficeVerifier]: { name: 'Back office verifier', value: Role.BackOfficeVerifier },
};

export const hrsdMemberRoles: RolesMap = {
  [Role.HrsdManager]: { name: 'HRSD Manager', nameArabic: 'مدير الوزارة', value: Role.HrsdManager },
  [Role.HrsdViewer]: { name: 'HRSD Viewer', nameArabic: 'مراقب الوزارة', value: Role.HrsdViewer },
  [Role.HrsdFastTrackManager]: {
    name: 'Fast Track Manager',
    nameArabic: 'مدير المسار السريع',
    value: Role.HrsdFastTrackManager,
  },
};

export const allRoles: RolesMap = {
  ...serviceProviderRoles,
  ...adminRoles,
  ...hrsdMemberRoles,
  [Role.SuperAdmin]: { name: 'Super Admin', value: Role.SuperAdmin },
  [Role.User]: { name: 'Customer service viewer', value: Role.User },
};
