import {
  fastTrackRecordsStatuses,
  FastTrackRequestAndRecordTableKeys,
  fastTrackRequestStatuses,
} from '@/common/constants/fast-track-requests';
import {
  allStatuses,
  done,
  inProgressAdminTable,
  onHold,
  pending,
  RequestTableKeys,
  withdrawn,
} from '@/common/constants/verification-form';

export const commonRequestsTables: Map<RequestTableKeys, any> = new Map([
  ['all', allStatuses],
  ['pending', pending],
  ['inProgress', [...inProgressAdminTable, ...onHold]],
  ['done', done],
  ['withdrawn', withdrawn],
  ['fastTrack', allStatuses],
]);

export const fastTrackRequestsTables: Map<FastTrackRequestAndRecordTableKeys, any> = new Map([
  ['immediate', fastTrackRequestStatuses],
  ['major', fastTrackRequestStatuses],
]);

export const fastTrackRecordsTables: Map<FastTrackRequestAndRecordTableKeys, any> = new Map([
  ['immediateRecords', fastTrackRecordsStatuses],
  ['majorRecords', fastTrackRecordsStatuses],
]);
