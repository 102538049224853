import { FileDetails } from '@/common/types/upload';

interface Details {
  translationKey: string;
  color: string;
}

// TODO: replace with StatusDetails
export interface StatusDetailsObject {
  name: string;
  nameArabic?: string;
  value: string;
  color: string;
}

export enum PaymentStatus {
  SUCCESS = 'success',
  INITIALIZED = 'initialized',
  NOT_INITIALIZED = 'not_initialized',
  FAILED = 'failed',
  PENDING = 'pending',
  // use only payments above
  ALL = 'all',
  SUSPENDED = 'suspended',
}

export enum RequestStatuses {
  // request/type - started, not finished
  DONE = 'done',
  DRAFTED = 'drafted',
  // request - payment completed successfully, type goes to status pending instead this
  PAID = 'paid',
  // request - manually set by administrator, from status paid
  REFUNDED = 'refunded',
  // request/type - finished, payment not started
  UNPAID = 'unpaid',
  // request/type - payment completed, processing
  PAYMENT_PENDING = 'payment_pending',
  // type - payment completed, request sent do SP
  PENDING = 'pending',
  // type - assigned to verifier
  IN_PROGRESS = 'in_progress',
  // type - set manually to hold
  ON_HOLD = 'on_hold',
  // type - returned by verifier to candidate
  RETURNED = 'returned',
  // type - updated by candidate, sent back to verifier
  UPDATED = 'updated',
  // type - verified successfully
  ACCEPTED = 'accepted',
  // type - verified unsuccessfully
  UNABLE_TO_VERIFY = 'unable_to_verify',
  // type - withdrawn by candidate
  WITHDRAWN = 'withdrawn',
  // type -
  ADDITIONAL_PAYMENT_REQUESTED = 'additional_payment_requested',

  // helper
  ALL = 'all',
}

export enum FastTrackReviewRequestActionStatus {
  // action request/type - reject request
  REJECT = 'reject',
  // action request/type - send back to update
  SEND_TO_UPDATE = 'send_to_update',
  // action request/type - accept request
  APPROVE = 'approve',
}
export enum FastTrackRequestStatuses {
  UPDATED = 'updated',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING_UPDATES = 'pending_updates',
  SUBMITTED = 'submitted',
}
export enum FastTrackRecordsStatuses {
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export enum FastTrackRecordStatuses {
  ACTIVE = 'active',
  CANCELED = 'canceled',
}

export enum VerificationRequestTypes {
  EDUCATION = 'education',
  EMPLOYMENT = 'employment',
}

export enum ContractStatuses {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  TERMINATED = 'terminated',
  NOT_AVAILABLE = 'not available',
}

export enum CompatibilityStatus {
  COMPATIBLE = 'compatible',
  INCOMPATIBLE = 'incompatible',
  UNABLE_TO_VERIFY = 'unable_to_verify',
  PENDING = 'pending',
}

export enum ReportStatus {
  WAITING = 'waiting',
  APPROVED = 'accepted',
}

export enum InvoiceStatus {
  NOT_SENT = 'not_sent',
  WAITING = 'waiting',
  FOR_UPDATE = 'for_update',
  PAYMENT_PENDING = 'payment_pending',
  PAID = 'paid',
  RETURNED = 'returned',
}

export enum BaseStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type StatusType =
  | RequestStatuses
  | ContractStatuses
  | PaymentStatus
  | CompatibilityStatus
  | ReportStatus
  | InvoiceStatus
  | BaseStatus
  | FastTrackRequestStatuses;

export type StatusDetails<T extends StatusType> = Record<T, Details>;

export interface RejectReasonResponse {
  id: number;
  reason: string;
  requireComment: boolean;
}

export interface ApiReasonDetails {
  reason: number;
  comment: string | null;
}

export interface RejectReason {
  reportFileId: string | null;
  reportFile?: FileDetails;
  reason: {
    id: number | null;
    comment: string | null;
  };
}
