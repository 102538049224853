<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M43.5523 30.1941H37.9973L33.8311 42.6928L25.4986 17.6953L21.3323 30.1941H15.7773"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HealthIcon',
});
</script>
