var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "61",
        viewBox: "0 0 60 61",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "30", cy: "30.5293", r: "30", fill: "#E69A35" },
      }),
      _c("path", {
        attrs: {
          d: "M20.638 42.6939C23.3224 42.6939 25.4986 40.5177 25.4986 37.8333C25.4986 35.1488 23.3224 32.9727 20.638 32.9727C17.9535 32.9727 15.7773 35.1488 15.7773 37.8333C15.7773 40.5177 17.9535 42.6939 20.638 42.6939Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.6917 42.6939C41.3761 42.6939 43.5523 40.5177 43.5523 37.8333C43.5523 35.1488 41.3761 32.9727 38.6917 32.9727C36.0072 32.9727 33.8311 35.1488 33.8311 37.8333C33.8311 40.5177 36.0072 42.6939 38.6917 42.6939Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.6653 37.8321V32.9715L25.499 28.8052L31.054 24.639L33.8315 28.8052H36.609M33.8315 21.8615C34.1998 21.8615 34.5531 21.7152 34.8135 21.4547C35.074 21.1943 35.2203 20.8411 35.2203 20.4727C35.2203 20.1044 35.074 19.7512 34.8135 19.4907C34.5531 19.2303 34.1998 19.084 33.8315 19.084C33.4632 19.084 33.11 19.2303 32.8495 19.4907C32.5891 19.7512 32.4428 20.1044 32.4428 20.4727C32.4428 20.8411 32.5891 21.1943 32.8495 21.4547C33.11 21.7152 33.4632 21.8615 33.8315 21.8615V21.8615Z",
          stroke: "white",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }