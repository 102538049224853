export const consts = {
  ContactUsHref: 'mailto:mail@example.com',
  TermsHref: '#',
  WelcomeImageCarouselIntervalMiliseconds: 5000,
  MinUserBirthDate: new Date(1900, 0, 2),
  MaxUserBirthDate: new Date(new Date().getFullYear() - 18, 0, 2),
  MinEnrollmentDate: new Date(1900, 0, 2),
  MaxEnrollmentDate: new Date(),
  MinContractExpiryDate: new Date(),
  MaxContractExpiryDate: new Date(new Date().getFullYear() + 10, 0, 2),
  MinPassportExpiryDate: new Date(),
  MaxPassportExpiryDate: new Date(new Date().getFullYear() + 30, 0, 2),
  MinRequestCreationDate: new Date(1900, 0, 2),
  MaxRequestCreationDate: new Date(),
  MaxFilterTransactionDate: new Date(),
  MinFastTrackRequestValidityDate: new Date(),
  MaxFastTrackRequestValidityDate: new Date(new Date().getFullYear() + 10, 0, 2),
};

export default consts;
