<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M43.5523 27.4164V35.7489V27.4164ZM15.7773 27.4164L29.6648 20.4727L43.5523 27.4164L29.6648 34.3602L15.7773 27.4164Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.332 30.1934V37.1371C25.4983 41.3034 33.8308 41.3034 37.997 37.1371V30.1934"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EducationIcon',
});
</script>
