import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export enum DateFormats {
  FULL_DATE = 'DD/MM/YYYY',
  FULL_DATE_REVERSE = 'YYYY/MM/DD',
  FULL_DATE_REVERSE_WITH_DASH = 'YYYY-MM-DD',
  FULL_DATE_WITH_TIME = 'DD/MM/YYYY HH:mm',
  FULL_DATE_WITH_FULL_TIME = 'DD/MM/YYYY, HH:mm:ss',
  MONTH_AND_YEAR = 'MMMM, YYYY',
  MONTH_AND_YEAR_WITH_DASH = 'MM-YYYY',
  YEAR_AND_MONTH_WITH_DASH = 'YYYY-MM',
  MONTH_AND_YEAR_NUMERIC = 'MM/YYYY',
  FULL_TIME = 'HH:mm:ss',
}

export const getFullDateObject = (date: string) => {
  const supportedFormats = [
    'YYYY-MM-DDTHH:mm:ss',
    'DD/MM/YYYY',
    'DD.MM.YYYY',
    'D.MM.YYYY',
    'DD-MM-YYYY',
    'YYYY-MM-DD',
    'MM-YYYY',
    'YYYY-MM',
    'DD/MM/YYYYTHH:mm:ss.sssZ',
    'HH:mm:ss',
  ];

  const format = supportedFormats.find((format) => dayjs(date, format).isValid());

  return format ? dayjs(date, format) : dayjs(date);
};

const getMonthAndYearObject = (date: string) => {
  const dateObject = getFullDateObject(date);

  return dayjs(dateObject, DateFormats.MONTH_AND_YEAR_WITH_DASH).isValid()
    ? dayjs(dateObject, DateFormats.MONTH_AND_YEAR_WITH_DASH)
    : dayjs(dateObject, DateFormats.YEAR_AND_MONTH_WITH_DASH);
};

export const getFormattedFullDate = (
  date?: string,
  format: DateFormats = DateFormats.FULL_DATE,
  defaultValue = '-',
) => {
  if (!date) return defaultValue;

  return getFullDateObject(date).format(format);
};

export const getFormattedDate = (
  date: string,
  format: DateFormats = DateFormats.MONTH_AND_YEAR,
  defaultValue = '-',
) => {
  if (!date) return defaultValue;

  return getMonthAndYearObject(date).format(format);
};

export function formatJsDate(date: Date) {
  const dateObj = { dd: date.getDate(), mm: date.getMonth() + 1, yyyy: date.getFullYear(), formattedDate: '' };

  dateObj.formattedDate = dateObj.formattedDate.concat(dateObj.yyyy.toString(), '/');

  if (dateObj.mm < 10) {
    dateObj.formattedDate = dateObj.formattedDate.concat('0', dateObj.mm.toString());
  } else {
    dateObj.formattedDate = dateObj.formattedDate.concat(dateObj.mm.toString());
  }

  if (dateObj.dd < 10) {
    dateObj.formattedDate = dateObj.formattedDate.concat('/0', dateObj.dd.toString());
  } else {
    dateObj.formattedDate = dateObj.formattedDate.concat('/', dateObj.dd.toString());
  }

  return dateObj.formattedDate;
}

export function getFirstDayPreviousMonth() {
  return formatJsDate(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
}

export function getFirstDayPreviousYear() {
  return formatJsDate(new Date(new Date().getFullYear() - 1, 0, 1));
}

export function getFirstDayCurrentMonth() {
  return formatJsDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
}

export function getFirstDayCurrentYear() {
  return formatJsDate(new Date(new Date().getFullYear(), 0, 1));
}

export function getLastDayPreviousMonth() {
  const date = new Date();
  date.setDate(0);
  return formatJsDate(date);
}

export function getLastDayPreviousYear() {
  return formatJsDate(new Date(new Date().getFullYear() - 1, 11, 31));
}

export function getTodayDay() {
  return formatJsDate(new Date());
}

export function formattedDate(val: string | null): string | null {
  if (!val) {
    return null;
  }

  return new Date(val).toLocaleDateString();
}

export function fromNow(date: string) {
  const parsedDate = dayjs(date, 'YYYY-MM-DDTHH:mm:ss:Z');

  return parsedDate.fromNow();
}
