import { RequestStatuses, Role } from '@/common/types';
import { AllowedMapToIdsEntities } from '@/common/types/occupations';

export function getEnumKeys(enumObj: any) {
  return Object.keys(enumObj).filter((key) => !Number.isNaN(Number(enumObj[key])));
}

export function isScrolledIntoView(el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export function isLetter(input: any) {
  const char = input.key; // Get the character
  if (/^[\s\p{L}]+$/u.test(char)) return true;
  return input.preventDefault();
}

export function isActionKey(input: any) {
  const char = input.key;
  const actionKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
  return actionKeys.includes(char);
}

export function isNumeric(input: any, allowDashes = false) {
  const char = input.key;
  if (allowDashes && char === '-') return true;
  if (/\d/.test(char) || char === '.') return true;
  return input.preventDefault();
}

export function isNumber(event: KeyboardEvent) {
  const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
  const keyPressed: string = event.key;

  if (!keysAllowed.includes(keyPressed)) {
    event.preventDefault();
  }
}

export function isSpace(input: any) {
  const char = input.key;
  if (/\s/g.test(char)) return input.preventDefault();
  return true;
}

export function catchNumbers(event: any) {
  if (event.key !== 'Meta' && event.key !== 'v') {
    const value = event.clipboardData.getData('text/plain');
    if (/\d/.test(value)) return event.preventDefault();
  }
  return event;
}

export function catchNonNumeric(event: any) {
  if (event.key !== 'Meta' && event.key !== 'v') {
    const value = event.clipboardData.getData('text/plain');
    if (!/\d./.test(value)) return event.preventDefault();
  }
  return event;
}

export function mapExtensionToType(extension: string) {
  if (extension === 'pdf') return 'application/pdf';
  return `image/${extension}`;
}

export function capitalizeFirstLetter(value: string) {
  return value?.charAt(0).toUpperCase() + value?.slice(1);
}

export function mapOccupationEntitiesToIds(entities: AllowedMapToIdsEntities[]): string[] {
  return entities.map((item) => (typeof item === 'string' || typeof item === 'number' ? item : item.id) as string);
}

export function generateRandomId() {
  return `${Math.floor(Math.random() * 10000).toString()}${new Date().valueOf().toString()}`;
}

export function mapRequestStatusForCandidate(typeStatus: string, requestStatus?: RequestStatuses) {
  if (requestStatus && [RequestStatuses.PAYMENT_PENDING].includes(requestStatus))
    return RequestStatuses.PAYMENT_PENDING;
  if (RequestStatuses.UPDATED === typeStatus) return RequestStatuses.IN_PROGRESS;
  if (RequestStatuses.ADDITIONAL_PAYMENT_REQUESTED === typeStatus) return RequestStatuses.IN_PROGRESS;
  return typeStatus === RequestStatuses.PENDING ? RequestStatuses.IN_PROGRESS : typeStatus;
}

export function calculatePublishRowClass(item: { isPublished?: boolean }): string {
  if (typeof item.isPublished === 'undefined') return '';
  return item.isPublished ? 'published' : 'unpublished';
}

export function getLoginPageName(role: string) {
  if (role === Role.User) return 'login';
  if (role === Role.ServiceProvider || role === Role.ServiceProviderManager) return 'ServiceProviderLogin';
  return 'AdminLogin';
}

export function formatUSD(value: number) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

export function censorPhoneNumber(phoneNumber: string | undefined, isRtl = false) {
  if (!phoneNumber?.length) return '-';
  const maskedPart = '*'.repeat(phoneNumber.length - 3);
  const lastThreeDigits = phoneNumber.slice(-3);

  return (isRtl ? lastThreeDigits + maskedPart : maskedPart + lastThreeDigits).replace(/.{3}(?=.)/g, '$& ');
}

export function applyQvpToRandomId(randomId: string) {
  return `QVP-${randomId}`;
}

export default getEnumKeys;
