import { ComputedRef } from 'vue';

import { Pagination, StatusDetailsObject } from '@/common/types';

export interface Settings {
  filters: any;
  pagination: Pagination;
}

export function findFiltersNumber(currentTableSettings: ComputedRef<Settings>) {
  if (!currentTableSettings.value) return 0;

  const excludedFilters = ['sortBy', 'order', 'receivedDateFrom', 'updatedDateFrom'];
  const filtersToCount = Object.entries(currentTableSettings.value?.filters).filter(([key, value]) => {
    if (excludedFilters.includes(key)) return false;

    if (Array.isArray(value)) {
      return value?.length > 0;
    }

    return !!value;
  });

  return filtersToCount?.length;
}

export function prepareFetchQuery(filter: any, statusesList: StatusDetailsObject[] = []) {
  const query = { ...filter };

  if ('statuses' in filter && !filter?.statuses?.length) {
    query.statuses = statusesList.map((val: StatusDetailsObject) => val.value);
  }
  if ('status' in filter && !filter?.status?.length) {
    query.status = statusesList.map((val: StatusDetailsObject) => val.value);
  }

  return query;
}
