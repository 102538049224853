import Vue from 'vue';
import VueGtag from 'vue-gtag';

import { getEnvironmentVar } from '@/common/utils';

// eslint-disable-next-line func-names
export default function () {
  if (['stage', 'production'].includes(getEnvironmentVar('NODE_ENV'))) {
    Vue.use(VueGtag, {
      config: {
        id: getEnvironmentVar('VUE_APP_GOOGLE_ANALYTICS_KEY'),
      },
    });
  }
}
