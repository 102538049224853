var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18 42C18 43.1 18.9 44 20 44H28C29.1 44 30 43.1 30 42V40H18V42ZM24 4C16.28 4 10 10.28 10 18C10 22.76 12.38 26.94 16 29.48V34C16 35.1 16.9 36 18 36H30C31.1 36 32 35.1 32 34V29.48C35.62 26.94 38 22.76 38 18C38 10.28 31.72 4 24 4ZM29.7 26.2L28 27.4V32H20V27.4L18.3 26.2C15.6 24.32 14 21.26 14 18C14 12.48 18.48 8 24 8C29.52 8 34 12.48 34 18C34 21.26 32.4 24.32 29.7 26.2Z",
          fill: "#E69A35",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }