import { OccupationBaseEntity } from '@/common/types/occupations';
import { SlaDetails } from '@/common/types/sla';
import { FileDetails } from '@/common/types/upload';

import { PaymentStatus, RequestStatuses } from './status';

export interface EmploymentData {
  occupation: OccupationBaseEntity | null;
  yearsOfExperience: number | null;
  jobOfferFile: Array<FileDetails> | null;
  majorEntityId: string | null;
  otherMajorEntityName: string | null;
}

export interface EmploymentDataPayload {
  occupationId: number;
  yearsOfExperience: number | null;
  jobOfferFileId: string;
}

export interface PassportDetails {
  firstName: string;
  secondName: string;
  lastName: string;
  gender: string;
  nationality: string;
  birthAt: string;
  passportNumber: string;
  passportExpireAt: string;
  countryOfResidence: string | null;
}

export interface VerificationPersonalDetailsPayload {
  firstName: string | null;
  secondName: string | null;
  lastName: string | null;
  alternateName: string | null;
  maidenName: string | null;
  formerMarriedName: string | null;
  suffix: string | null;
  mothersName: string | null;
  gender: string | null;
  nationality: string | null;
  birthAt: string | null;
  countryOfResidence: string | null;
  currentResidenceLocation: string | null;
  state: string | null;
  passportNumber: string | null;
  passportExpireAt: string | null;
  passport: object | null;
  consent: Array<FileDetails>;
  requestReason: string;
  firstNameIsValid?: string;
  secondNameIsValid?: string;
  lastNameIsValid?: string;
  alternateNameIsValid?: string;
  maidenNameIsValid?: string;
  formerMarriedNameIsValid?: string;
  suffixIsValid?: string;
  mothersNameIsValid?: string;
  nationalityIsValid?: string;
  birthAtIsValid?: string;
  passportNumberIsValid?: string;
  passportExpireAtIsValid?: string;
  countryOfResidenceIsValid?: string;
  currentResidenceLocationIsValid?: string;
  stateIsValid?: string;
  genderIsValid?: string;
  consentIsValid?: string;
  passportIsValid?: string;
  requestReasonIsValid?: string;
}

export interface EducationData {
  status?: string;
  educationPlaceId: string | null;
  educationInstitute: string | null;
  studentId: string | null;
  studentEmail: string | null;
  college: string | null;
  instituteStreet: string | null;
  instituteBuildingNumber: string | null;
  instituteCity: string | null;
  instituteState: string | null;
  institutePostalCode: string | null;
  instituteCountry: string | null;
  institutePhoneNumber: string | null;
  institutePhoneNumberCode: string | null;
  institutePhoneNumberCountryCode: string | null;
  instituteWebAddress: string | null;
  educationalLevel: string | null;
  majorName: string | null;
  majorId?: number;
  startDateOfEnrollment: string | null;
  endDateOfEnrollment: string | null;
  nameOnRecord: string | null;
  awards: Array<object> | null;
  awardName: string | null;
  awardIssuedAt: string | null;
  certificates: Array<object> | null;
  apostilleCertificate: Array<object> | null;
  educationInstituteIsValid?: string;
  studentIdIsValid?: string;
  studentEmailIsValid?: string;
  collegeIsValid?: string;
  educationalLevelIsValid?: string;
  instituteStreetIsValid?: string;
  instituteBuildingNumberIsValid?: string;
  instituteCityIsValid?: string;
  instituteStateIsValid?: string;
  institutePostalCodeIsValid?: string;
  instituteCountryIsValid?: string;
  institutePhoneNumberIsValid?: string;
  instituteWebAddressIsValid?: string;
  majorNameIsValid?: string;
  startDateOfEnrollmentIsValid?: string;
  endDateOfEnrollmentIsValid?: string;
  nameOnRecordIsValid?: string;
  awardsIsValid?: string;
  certificatesIsValid?: string;
  awardNameIsValid?: string;
  awardIssuedAtIsValid?: string;
  requestVerifierUserId?: string;
  apostilleCertificateIsValid?: Array<object> | null;
}

export interface AgreementsData {
  dataValidityConfirmation: boolean;
  termsAndConditionsAcceptance: boolean;
}

export interface RequestDetailsKeys {
  name: string;
  subname?: string;
  adminOnly: boolean;
}

export enum PersonalDetailsKeys {
  firstName,
  secondName,
  lastName,
  alternateName,
  formerMarriedName,
  maidenName,
  suffix,
  mothersName,
  gender,
  nationality,
  birthAt,
  passportNumber,
  passportExpireAt,
  countryOfResidence,
  state,
  currentResidenceLocation,
  requestReason,
}

export enum EducationDetailsKeys {
  educationPlaceId,
  educationInstitute,
  college,
  instituteStreet,
  instituteBuildingNumber,
  instituteCity,
  instituteState,
  instituteWebAddress,
  educationalLevel,
  majorName,
  startDateOfEnrollment,
  endDateOfEnrollment,
  instituteCountry,
  institutePhoneNumber,
  institutePhoneNumberCode,
  institutePhoneNumberCountryCode,
  institutePostalCode,
  nameOnRecord,
  studentId,
  studentEmail,
  awardName,
  awardIssuedAt,
}

export enum EmploymentDetailsKeys {
  yearsOfExperience,
}

export const VerificationFormKeys = {
  ...PersonalDetailsKeys,
  ...EducationDetailsKeys,
  ...EmploymentDetailsKeys,
};

export enum FilesFieldsKeys {
  passport,
  consent,
  certificates,
  awards,
  educationInstituteReport,
}

export interface VerificationRequestOverview {
  randomId: string;
  requestId: string;
  id: string;
  paymentStatus?: PaymentStatus;
  sendDate: string;
  status: RequestStatuses;
  statusUpdate: string;
  educationalLevel: string;
  majorName: string;
  reportFileId: string;
  appealAvailable: boolean;
  certificateFileId: string;
  slaDaysInProgress: string;
  slaDetails?: SlaDetails;
  slaHoldEntityId: string;
  invoiceFileId?: string;
  jobOfferComplete: boolean;
  summaryStatusCheck: string;
}

export interface Pagination {
  page?: number;
  limit?: number;
}

export interface List<T> {
  count: number;
  data: T[];
}

export interface VerificationRequest {
  id: string;
  randomId: string;
  appealAvailable: boolean;
  requestId: string;
  sendDate: string;
  endDate: string;
  status: string;
  statusUpdate: string;
  firstName: string;
  lastName: string;
  secondName: string;
  nationality: string;
  country: string;
  passportNumber: string;
  contractData: string;
  verifier: string;
  assignee: string;
  currentResidenceLocation: string;
  majorStatusCheck: string;
  educationLevelStatusCheck: string;
  type: 'education' | 'employment';
  hasApostilleCertificate: boolean;
}

export interface Employee {
  id: string;
  userId?: string;
  firstName: string;
  lastName: string;
}

export interface Document {
  id: string;
  documentName: string;
  documentPath: string;
  createdAt: string;
}

export interface VerifyRequestData {
  firstName: boolean;
  secondName: boolean;
  lastName: boolean;
  alternateName: boolean;
  maidenName: boolean;
  formerMarriedName: boolean;
  suffix: boolean;
  mothersName: boolean;
  educationalLevel: boolean;
  educationInstitute: boolean;
  majorName: boolean;
  instituteStreet: boolean;
  instituteBuildingNumber: boolean;
  instituteCity: boolean;
  instituteState: boolean;
  institutePostalCode: boolean;
  instituteCountry: boolean;
  institutePhoneNumber: boolean;
  institutePhoneNumberCode: boolean;
  institutePhoneNumberCountryCode: boolean;
  instituteWebAddress: boolean;
  college: boolean;
  nameOnRecord: boolean;
  startDateOfEnrollment: boolean;
  endDateOfEnrollment: boolean;
  passportNumber: boolean;
  passportExpireAt: boolean;
  countryOfResidence: boolean;
  gender: boolean;
  birthAt: boolean;
  nationality: boolean;
  comment: string | null;
  passport: boolean;
  consent: boolean;
  awards: boolean;
  certificates: boolean;
  actualJobOffer?: boolean;
  establishmentName?: boolean;
  studentId?: boolean;
  studentEmail?: boolean;
  awardName?: boolean;
  awardIssuedAt?: boolean;
  state: boolean;
  currentResidenceLocation: boolean;
  requestReason?: boolean;
  educationInstituteReport: boolean;
  educationPlaceId: boolean;
  educationInstituteReportFileId?: string | null;
}

export interface VerifyApostilleRequestData {
  apostilleCertificate: boolean;
  comment: string | null;
}

export interface Comment {
  type: any;
  id: string;
  content: string;
  createdAt: Date;
  candidate: boolean;
  fieldReturned: Array<string>;
}

export interface VerificationRequestSummary {
  requestVerifierUserId: any;
  id: any;
  randomId: string;
  sendDate: string;
  status: string;
  firstName: string;
  secondName: string | null;
  lastName: string;
  alternateName: string | null;
  maidenName: string | null;
  formerMarriedName: string | null;
  suffix: string | null;
  mothersName: string | null;
  gender: string;
  nationality: string;
  birthAt: string;
  countryOfResidence: string;
  passportNumber: string;
  passportExpireAt: string;
  education: {
    id: string;
    requestId: string;
    status: string;
    educationInstitute: string;
    college: string;
    instituteStreet: string;
    instituteBuildingNumber: string;
    instituteCity: string;
    instituteState: string;
    institutePostalCode: string;
    instituteCountry: string;
    institutePhoneNumber: string;
    institutePhoneNumberCode?: string;
    institutePhoneNumberCountryCode?: string;
    instituteWebAddress: string;
    startDateOfEnrollment: string;
    endDateOfEnrollment: string;
    educationalLevel: string;
    educationInstituteReport: Array<FileDetails>;
    awards: Array<FileDetails>;
    certificates: Array<FileDetails>;
    apostilleCertificate: Array<FileDetails>;
    studentId?: string;
    studentEmail?: string;
    awardName?: string;
    awardIssuedAt?: string;
    nameOnRecord: string;
    slaDaysInProgress: number | null;
    slaDaysOnHold: number | null;
    slaHoldEntityId: string;
  };
  actualJobOffer: boolean;
  establishmentName: string;
  passport: Array<FileDetails>;
  consent: Array<FileDetails>;
  attachments: Array<FileDetails>;
  documents: Document[];
  receivedDate: string;
  companyName?: string;
  requestAssignedTo?: string;
  statusUpdate?: string;
  appealAvailable?: boolean;
  state: string;
  currentResidenceLocation: string;
  requestReason?: string;
  jobOfferFile: FileDetails[];
  occupation: OccupationBaseEntity;
  otherMajorEntityName?: string;
  majorEntityId?: string;
  fastTrack?: boolean;
}

export interface VerificationData {
  content: string;
  id: string;
  isCandidateMessage?: boolean;
}

export interface EducationInstitute {
  id: string;
  name: string;
  formattedAddress: string;
}

export interface EducationInstituteAddress {
  country: string;
  state: string;
  city: string;
  buildingNumber: string;
  street: string;
  postalCode: string;
}

export interface EducationInstituteDetails {
  id: string;
  name: string;
  address: EducationInstituteAddress;
  phoneNumber: string;
  webAddress: string;
  countryCode: string;
}

export interface VerificationFlowDataResponse {
  personalData: VerificationPersonalDetailsPayload;
  employmentData: EmploymentData;
  educationData: EducationData;
  comment: VerificationData[];
  reportFileId: string;
  status: string;
  educationAppealAvailable: boolean;
  educationInstituteReport: Array<FileDetails>;
  randomId: number;
}

export interface PaymentPayload {
  id?: string;
  verificationRequestId: string;
}

export interface PaymentRecord {
  gross: number;
  net: number;
  tax: number;
  taxPercentage: number;
  title: string;
  type: 'institute_fee' | 'education' | 'employment';
}

export interface PaymentDetails {
  gross: number;
  net: number;
  tax: number;
  taxPercentage: number;
  checkoutId?: string;
  paymentRecords: PaymentRecord[];
}

export interface RequestCreationResponse {
  requestId: string;
}

export type VerificationFlowDataPayload = EmploymentData &
  VerificationPersonalDetailsPayload &
  EducationData &
  AgreementsData;

export interface PhoneNumberDetails {
  countryCallingCode: string;
  countryCode: string;
  e164: string;
  formatInternational: string;
  formatNational: string;
  isValid: boolean;
  nationalNumber: string;
  phoneNumber: string;
  type: string | undefined;
  uri: string;
}

export interface SignatureProps {
  signature: string;
  isDrawnSignature: boolean;
}

export interface ESignDetails {
  firstName: string;
  lastName: string;
  passportNumber: string;
  verificationId: string;
  drawnSign?: string;
  typedSign?: string;
}

export interface CandidateDashboardFilters {
  status: string[];
  search: string;
}
