import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';

import initializeGoogleAnalytics from '@/../scripts/google-analytics';
import initializeHotjar from '@/../scripts/hotjar';
import { getEnvironmentVar, injectZendesk, requestEnvironmentVariables } from '@/common/utils';

const runApp = async () => {
  await requestEnvironmentVariables();
  initializeGoogleAnalytics();
  initializeHotjar();
  injectZendesk(getEnvironmentVar('VUE_APP_ZENDESK_KEY'));

  const vm = await import('./app');

  vm.default.$mount('#app');
};

runApp();
