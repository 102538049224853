/* eslint-disable camelcase */
export interface GeocodingFeature {
  id: string;
  relevance: number;
  place_name: string;
  context: { id: string; text_en: string; short_code: string }[];
}

export enum GeocodingContextType {
  Place = 'place',
  Region = 'region',
  Country = 'country',
  District = 'district',
}

export interface GeocodingContextItem {
  id: string;
  text_en: string;
  short_code?: string;
}

export interface GeocodingContextItemTO {
  type: string;
  text: string;
  code?: string;
}

export interface GeocodingCountryContext extends GeocodingContextItemTO {
  type: GeocodingContextType.Country;
}

export interface GeocodingPlaceContext extends GeocodingContextItemTO {
  type: GeocodingContextType.Place;
}

export interface GeocodingRegionContext extends GeocodingContextItemTO {
  type: GeocodingContextType.Region;
}

export interface GeocodingDistrictContext extends GeocodingContextItemTO {
  type: GeocodingContextType.District;
}

export function isGeocodingCountryContext(
  candidate: GeocodingContextItemTO | null,
): candidate is GeocodingCountryContext {
  return !!candidate && candidate.type === GeocodingContextType.Country;
}

export function isGeocodingPlaceContext(candidate: GeocodingContextItemTO | null): candidate is GeocodingPlaceContext {
  return !!candidate && candidate.type === GeocodingContextType.Place;
}

export function isGeocodingDistrictContext(
  candidate: GeocodingContextItemTO | null,
): candidate is GeocodingDistrictContext {
  return !!candidate && candidate.type === GeocodingContextType.District;
}

export function isGeocodingRegionContext(
  candidate: GeocodingContextItemTO | null,
): candidate is GeocodingRegionContext {
  return !!candidate && candidate.type === GeocodingContextType.Region;
}

export interface GeocodingFeatures {
  features: GeocodingFeature[];
}

export interface GeocodingFeatureTO {
  text: string;
  context: (GeocodingCountryContext | GeocodingPlaceContext | GeocodingDistrictContext | GeocodingRegionContext)[];
}
