<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="30"
      cy="30.5293"
      r="30"
      fill="#E69A35"
    />
    <path
      d="M40.7748 17.6953H18.5548C17.0209 17.6953 15.7773 18.9388 15.7773 20.4728V34.3603C15.7773 35.8943 17.0209 37.1378 18.5548 37.1378H40.7748C42.3088 37.1378 43.5523 35.8943 43.5523 34.3603V20.4728C43.5523 18.9388 42.3088 17.6953 40.7748 17.6953Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.1104 42.6934H35.2204"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.665 37.1387V42.6937"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MediaIcon',
});
</script>
